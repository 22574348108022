import {
    DayNames,
    EmployeeDailyTimePlanCollection, EmployeeWeeklyTimePlan,
    EmployeeWeeklyTimePlanCollection,
} from '../../../../types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { RecordCreatorProps } from '@ant-design/pro-table/es/components/EditableTable';
import { ButtonProps } from 'antd';

export const convertDailyTimePlanToWeeklyTimePlan = (
    dailyTimePlanCollection: EmployeeDailyTimePlanCollection,
): EmployeeWeeklyTimePlanCollection => {
    return dailyTimePlanCollection.data.reduce(
        (weeklyTimePlanCollection: EmployeeWeeklyTimePlanCollection, dailyTimePlan) => {
            const { scheduled_start_date, scheduled_end_date } = dailyTimePlan;
            const scheduledStartDate = dayjs(scheduled_start_date).tz('utc');
            const scheduledEndDate = dayjs(scheduled_end_date).tz('utc');
            const scheduled_times: [string, string] = [
                scheduledStartDate.format('HH:mm'),
                scheduledEndDate.format('HH:mm'),
            ];

            let row = weeklyTimePlanCollection.find((o) => (
                o.scheduled_times[0] === scheduled_times[0]
                && o.scheduled_times[1] === scheduled_times[1]
                && o.role_id === dailyTimePlan.role_id
                && (dailyTimePlan.id === null || o.id === dailyTimePlan.row_id)
            ));

            if (!row) {
                row = {
                    id: dailyTimePlan.row_id,
                    role_id: dailyTimePlan.role_id,
                    scheduled_times,
                    monday: null,
                    tuesday: null,
                    wednesday: null,
                    thursday: null,
                    friday: null,
                    saturday: null,
                    sunday: null,
                    monday_col_id: null,
                    tuesday_col_id: null,
                    wednesday_col_id: null,
                    thursday_col_id: null,
                    friday_col_id: null,
                    saturday_col_id: null,
                    sunday_col_id: null,
                };

                weeklyTimePlanCollection.push(row);
            }

            const dayOfWeek = scheduledStartDate
                .locale('en')
                .format('dddd')
                .toLowerCase() as DayNames;

            row[dayOfWeek] = dailyTimePlan.employee_id;
            row[`${dayOfWeek}_col_id`] = dailyTimePlan.id;

            return weeklyTimePlanCollection;
        },
        [],
    );
};

export const getNewRecord = (): EmployeeWeeklyTimePlan => ({
    id: uuidv4(),
    scheduled_times: [],
    role_id: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    monday_col_id: null,
    tuesday_col_id: null,
    wednesday_col_id: null,
    thursday_col_id: null,
    friday_col_id: null,
    saturday_col_id: null,
    sunday_col_id: null,
});

export const getRecordCreatorProps = (disabled: boolean): RecordCreatorProps<EmployeeWeeklyTimePlan> & ButtonProps => ({
    position: 'bottom',
    newRecordType: 'dataSource',
    disabled: disabled,
    record: getNewRecord(),
});
