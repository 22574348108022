import React, { ReactNode } from 'react';
import { Space, Dropdown, Typography } from 'antd';
import { EyeOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import RoleFilterSelect from './Filters/RoleFilterSelect';
import { getListCompanies } from '../../../../utils/network/api/v1';
import BranchFilterSelect from './Filters/BranchFilterSelect';
const { Paragraph } = Typography;

const onMenuClick = (navigate: Function, id: number) => (e: any) => {
    const { key } = e;

    switch (key) {
        case 'details':
            navigate(`/files/personnel/${id}`);
            break;
        case 'edit':
            navigate(`/files/personnel/${id}/edit`);
            break;
        default:
            break;
    }
};

const actionItems = (ct: Function) => [
    {
        key: 'details',
        icon: <EyeOutlined style={{ color: 'green' }} />,
        label: <span>{ct('details')}</span>,
    },
    {
        key: 'edit',
        icon: <EditOutlined style={{ color: 'blue' }} />,
        label: <span>{ct('edit')}</span>,
    },
];

const columns = (
    t: Function,
    ct: Function,
    navigate: Function,
) => ([
    {
        title: ct('id'),
        dataIndex: 'id',
        width: 48,
        sorter: true,
        initialValue: '',
    },
    {
        title: t('full-name'),
        dataIndex: 'full_name',
        sorter: true,
        render: (fullName: ReactNode, o: any) => (
            <Paragraph
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => navigate(`/files/personnel/${o.id}`)}
                copyable
            >
                {fullName}
            </Paragraph>
        ),
    },
    {
        name: 'company_id',
        title: t('company'),
        valueType: 'select',
        hideInTable: true,
        request: async () => {
            return await getListCompanies().then((res) => {
                return [
                    { label: 'All', value: '' },
                    ...res.data.map((company: any) => ({
                        value: company.id,
                        label: company.name,
                    })),
                ];
            });
        },
    },
    {
        name: 'role_id',
        title: t('role'),
        valueType: 'select',
        dataIndex: 'role_id',
        sorter: true,
        render: (x: any, o: any) => {
            if (o.contracts.length === 1) {
                return (
                    <span>
                        <b>{o.contracts[0].role.name}</b>
                        <i>&nbsp;({o.contracts[0].branch.name}, {o.contracts[0].branch.company.name})</i>
                    </span>
                );
            }

            if (o.contracts.length > 1) {
                return (
                    <ul style={{ padding: 0, margin: 0, paddingLeft: 15 }}>
                        {o.contracts.map((contract: any) => (
                            <li key={contract.id}>
                                <b>{contract.role.name}</b>
                                <i>&nbsp;({contract.branch.name}, {contract.branch.company.name})</i>
                            </li>
                        ))}
                    </ul>
                )
            }

            return '-';
        },
        renderFormItem: (
            item: any,
            {
                type,
                defaultRender,
                ...rest
            }: { type: any, defaultRender: any, value?: any, onChange?: any },
            form: any,
        ) => {
            if (type === 'form') {
                return null;
            }

            return (
                <RoleFilterSelect
                    company_id={form.getFieldValue(['company_id'])}
                    branch_id={form.getFieldValue(['branch_id'])}
                    {...rest}
                />
            );
        },
    },
    {
        name: 'branch_id',
        title: t('branch'),
        dataIndex: ['contracts', 0, 'branch', 'name'],
        valueType: 'select',
        renderFormItem: (
            item: any,
            {
                type,
                defaultRender,
                ...rest
            }: { type: any, defaultRender: any, value?: any, onChange?: any },
            form: any,
        ) => {
            if (type === 'form') {
                return null;
            }

            return (
                <BranchFilterSelect
                    company_id={form.getFieldValue(['company_id'])}
                    {...rest}
                />
            );
        },
    },
    {
        hideInSearch: true,
        title: <span><SettingOutlined/> {t('actions')}</span>,
        render: (o: any) => (
            <Space direction="vertical">
                <Dropdown.Button
                    menu={{
                        items: actionItems(ct),
                        onClick: onMenuClick(navigate, o.id),
                    }}
                    onClick={() => navigate(`/files/personnel/${o.id}/edit`)}
                >
                    <EditOutlined/> {ct('edit')}
                </Dropdown.Button>
            </Space>
        ),
    },
]);

export default columns;
