import React, { useMemo, useRef, useState } from 'react';
import { Avatar, Select, Space, Card, Spin, Divider, Button, Typography } from 'antd';
import { getListEmployeeByQueryString } from '../../../../utils/network/api/v1';
import { EmployeeMetaResource, ListedEmployeeData } from '../../../../types';
import debounce from 'lodash.debounce';
import {
    InfoCircleOutlined,
    ExpandAltOutlined,
    ShrinkOutlined,
} from '@ant-design/icons';
import { getEmployeeMeta } from '../../../../utils/network/api/v1';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;


type PersonnelSelectProps = {
    roleId?: number,
    value?: number,
    onSelect: (value: ListedEmployeeData) => void,
    onChange?: (value: number) => void,
    selectedEmployees: ListedEmployeeData[],
    disabled: boolean,
};

const { OptGroup, Option } = Select;
const { Meta } = Card;

const renderOptionDefault = (employee: any, keyPrefix: string, loadEmployeePreview: any) => (
    <Option key={`${keyPrefix}-${employee.id}`} value={employee.id} label={employee.name}>
        <Space>
            {employee.name}
            <InfoCircleOutlined
                onClick={(e) => {
                    e.stopPropagation();

                    loadEmployeePreview(employee.id);
                }}
            />
        </Space>
    </Option>
);

const renderOptionExpanded = (employee: ListedEmployeeData, keyPrefix: string) => (
    <Option key={`${keyPrefix}-${employee.id}`} value={employee.id} label={employee.name}>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <div>
                <Avatar src={employee.photo_url}/>&nbsp;
                <span style={{ display: 'inline-block' }}>{employee.name}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {employee.contracts.map((contract) => (
                    <Text key={contract.id} style={{ fontSize: 10 }} ellipsis>
                        {contract.role.name}, {contract.branch.name}
                    </Text>
                ))}
            </div>
        </div>
    </Option>
);


const PersonnelSelect: React.FC<PersonnelSelectProps> = ({
    roleId,
    value,
    onSelect,
    onChange,
    selectedEmployees,
    disabled,
}) => {
    const { t } = useTranslation('personnel-planning');
    const { t: ct } = useTranslation('common');
    const fetchRef = useRef(0);
    const [
        fetchingEmployees,
        setFetchingEmployees,
    ] = useState(false);
    const [
        employees,
        setEmployees,
    ] = useState<ListedEmployeeData[]>([]);
    const [
        employeeMeta,
        setEmployeeMeta,
    ] = useState<EmployeeMetaResource | null>(null);
    const [
        fetchingEmployeeMeta,
        setFetchingEmployeeMeta,
    ] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const renderOption = !expanded
        ? (employee: ListedEmployeeData, keyPrefix: string) => renderOptionDefault(employee, keyPrefix, loadEmployeePreview)
        : (employee: ListedEmployeeData, keyPrefix: string) => renderOptionExpanded(employee, keyPrefix);

    const debounceEmployeeFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            setEmployeeMeta(null);
            fetchRef.current += 1;
            const fetchId = fetchRef.current;

            if (value.length < 1) {
                setEmployees([]);
                return;
            }

            setFetchingEmployees(true);
            getListEmployeeByQueryString(value, roleId).then((employees) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setEmployees(employees.data);
            }).finally(() => {
                setFetchingEmployees(false);
            });
        };

        return debounce(loadOptions, 200);
    }, [
        roleId,
    ]);

    const loadEmployeePreview = (employeeId: number) => {
        setFetchingEmployeeMeta(true);
        getEmployeeMeta(employeeId).then((employeeMeta) => {
            setEmployeeMeta(employeeMeta.data);
        }).finally(() => {
            setFetchingEmployeeMeta(false);
        });
    };

    return (
        <Select
            disabled={disabled}
            notFoundContent={fetchingEmployees ? <Spin size="small"/> : null}
            placeholder={ct('input.select.placeholder')}
            value={value}
            loading={fetchingEmployees}
            optionLabelProp="label"
            onSearch={debounceEmployeeFetcher}
            onChange={onChange}
            onSelect={(value, option) => {
                onSelect([
                    ...employees,
                    ...selectedEmployees,
                ].find((e) => e.id === value) as ListedEmployeeData);
            }}
            onClear={() => setEmployeeMeta(null)}
            filterOption={false}
            showSearch
            allowClear
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }}/>
                    {!expanded && (
                        <Space style={{ width: '100%', padding: '0 8px 4px' }}>
                            {employeeMeta ? (
                                <div>
                                    <Card
                                        loading={fetchingEmployeeMeta}
                                        style={{ width: '300' }}
                                    >
                                        <Meta
                                            avatar={<Avatar src={employeeMeta.photo_url}/>}
                                            title={employeeMeta.full_name}
                                            description={(
                                                <>
                                                    {employeeMeta.role_name} <br/>
                                                    {employeeMeta.branch_name}
                                                </>
                                            )}
                                        />
                                    </Card>
                                </div>
                            ) : (
                                <span>
                                    {t('personnel-select.preview-hint')}
                                </span>
                            )}
                        </Space>
                    )}
                </>
            )}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
                minWidth: expanded ? 400 : 260,
            }}
        >
            <OptGroup
                key={uuidv4()}
                label={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{t('personnel-select.option-groups.search-results.title')} ({employees.length})</span>
                        <Button
                            size="small"
                            icon={expanded ? <ShrinkOutlined/> : <ExpandAltOutlined/>}
                            onClick={() => setExpanded(!expanded)}
                            type="dashed"
                        />
                    </div>
                }
            >
                {employees.length ? employees.map((e) => renderOption(e, 'sr')) : (
                    <Option disabled key={uuidv4()}>
                        {t('personnel-select.start-typing-to-search')}
                    </Option>
                )}
            </OptGroup>
            <OptGroup
                key={uuidv4()}
                label={t('personnel-select.option-groups.previously-selected-results.title')}
            >
                {
                    selectedEmployees.length
                        ? selectedEmployees
                            .sort((a, b) => {
                                return a.contracts.find((c) => c.role.id === roleId) ? -1 : 1;
                            })
                            .map((e) => renderOption(e, 'se'))
                        : (<Option disabled key={uuidv4()}>-</Option>)
                }
            </OptGroup>
        </Select>
    );
};

export default PersonnelSelect;
