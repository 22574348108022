import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import {
    PageContainer, ProFormInstance,
    ProTable,
} from '@ant-design/pro-components';
import { FileAddOutlined } from '@ant-design/icons';
import columnDefinitions from './columns';
import cleanFilters from '../../../../utils/cleanFilters';
import { getEmployees } from '../../../../utils/network/api/v1';

const PersonnelFileIndex: React.FC = () => {
    const formRef = useRef<ProFormInstance>();
    const [stateFilter, setStateFilter] = React.useState<string>('all'); // ['all', 'active', 'inactive'
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'index',
    });
    const { t: ct } = useTranslation('common');
    const navigate = useNavigate();

    const columns = useMemo(
        () => columnDefinitions(t, ct, navigate),
        [t, ct, navigate],
    );

    return (
        <PageContainer>
            <ProTable
                formRef={formRef}
                rowKey="id"
                columns={columns}
                columnsState={{
                    persistenceType: 'sessionStorage',
                    persistenceKey: 'personnel-files',
                }}
                toolbar={{
                    menu: {
                        type: 'tab',
                        activeKey: stateFilter,
                        items: [
                            {
                                key: 'all',
                                label: t('filters.state.options.all'),
                            },
                            {
                                key: 'active',
                                label: t('filters.state.options.active'),
                            },
                            {
                                key: 'inactive',
                                label: t('filters.state.options.inactive'),
                            },
                        ],
                        onChange: (key) => {
                            setStateFilter(key as string);

                            formRef.current?.submit();
                        },
                    },
                }}
                toolBarRender={() => [
                    <Button key="1" type="primary" onClick={() => navigate('/files/personnel/new')}>
                        <FileAddOutlined/> {t('create-personnel-file')}
                    </Button>,
                ]}
                request={async (params = {}, sort = {}) => {
                    const sortBy = Object.keys(sort).filter((key) => sort[key] !== null)[0] || null;
                    const sortOrder = sortBy ? sort[sortBy] : null;

                    const filters = cleanFilters({
                        id: params.id,
                        full_name: params.full_name,
                        company_id: params.company_id,
                        role_id: params.role_id,
                        branch_id: params.branch_id,
                        state: stateFilter,
                    });

                    return await getEmployees(
                        params.current,
                        params.pageSize,
                        sortBy,
                        sortOrder,
                        filters,
                    ).then((res) => {
                        return {
                            data: res.data.data,
                            success: true,
                            total: res.data.meta.total,
                        };
                    });
                }}
            />
        </PageContainer>
    );
};

export default PersonnelFileIndex;
