import React, { useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    downloadPersonnelPlanningPDF,
    downloadPersonnelPlanningPDFDetailed,
} from '../../../../utils/network/api/v1';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

type ToolbarProps = {
    branchId: number,
    week: Dayjs,
    planType: string,
    disableDownloadPdfButton: boolean;
    disableDownloadDetailedPdfButton: boolean;
}

const Toolbar = ({
    branchId,
    week,
    planType,
    disableDownloadPdfButton,
    disableDownloadDetailedPdfButton,
}: ToolbarProps) => {
    const { t: ct } = useTranslation('common');

    const [downloads, setDownloads] = useState<{[key:string]: boolean}>({
        pdf: false,
        detailedPdf: false,
    });

    const handleDownloadPersonnelPlanningPDF = useCallback(async () => {
        setDownloads({
            ...downloads,
            pdf: true,
        })
        await downloadPersonnelPlanningPDF(
            branchId!,
            week!,
            planType!,
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const match = response.headers['content-disposition'].match(/filename="([^"]+)"/);
            const filename = match[1];

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setDownloads({ ...downloads, pdf: false });
        })
            .catch(error => {
                console.error(error);

                setDownloads({ ...downloads, pdf: false });
                return [];
            });
    }, [
        branchId,
        week,
        planType,
        downloads,
    ]);

    const handleDownloadPersonnelPlanningPDFDetailed = useCallback(async () => {
        setDownloads({
            ...downloads,
            detailedPdf: true,
        });

        await downloadPersonnelPlanningPDFDetailed(
            branchId!,
            week!,
            planType!,
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const match = response.headers['content-disposition'].match(/filename=([^"]+)/);
            const filename = match[1];

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setDownloads({ ...downloads, detailedPdf: false });
        })
            .catch(error => {
                console.error(error);

                setDownloads({ ...downloads, detailedPdf: false });
                return [];
            });
    }, [
        branchId,
        week,
        planType,
        downloads,
    ]);

    return (
        <>
            <Button
                key="download-pdf"
                type="default"
                disabled={disableDownloadPdfButton}
                icon={downloads.pdf ? <LoadingOutlined /> : <DownloadOutlined/>}
                onClick={
                    downloads.pdf
                        ? () => message.loading(ct('download.in-progress'))
                        : handleDownloadPersonnelPlanningPDF
                }
            >
                {ct('download.pdf')}
            </Button>,
            <Button
                key="download-pdf-detailed"
                type="default"
                disabled={disableDownloadDetailedPdfButton}
                icon={downloads.detailedPdf ? <LoadingOutlined /> : <DownloadOutlined/>}
                onClick={
                    downloads.detailedPdf
                        ? () => message.loading(ct('download.in-progress'))
                        : handleDownloadPersonnelPlanningPDFDetailed
                }
            >
                {ct('download.pdf-named', { name: 'Detailed'})}
            </Button>,
        </>
    )
}

export default Toolbar
