import React from 'react';
import { Dropdown } from 'antd';
import {
    ProLayout as BaseProLayout,
    ProSettings,
} from '@ant-design/pro-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import defaultProps from '../../_defaultProps';
import logoFull from '../../assets/logo-full-white.svg';
import { LoginResponse } from '../../utils/network/api/v1/types';

type ProLayoutProps = {
    children: React.ReactNode;
};

const ProLayout: React.FC<ProLayoutProps> = ({ children }) => {
    const navigate = useNavigate();

    const settings: Partial<ProSettings> = {
        layout: 'mix',
        navTheme: 'light',
        contentWidth: 'Fluid',
        fixSiderbar: true,
        colorPrimary: '#1677FF',
        siderMenuType: 'group',
        menu: {
            collapsedShowGroupTitle: true,
        },
        title: '',
    };

    const { t } = useTranslation('identity');
    const location = useLocation();
    const user = useAuthUser() as LoginResponse['user'] || null;
    const signOut = useSignOut();

    const pathname = location.pathname;

    return (
        <BaseProLayout
            onMenuHeaderClick={() => {
                navigate('/');
            }}
            logo={logoFull}
            itemRender={(
                route: any,
                params: any,
                routes: any,
                paths: any
            ) => {
                return (
                    <a href={'path' in route ? route.path : '/'}>
                        {'title' in route ? route.title : ''}
                    </a>
                );
            }}
            token={{
                header: {
                    colorBgHeader: '#292f33',
                    colorHeaderTitle: '#fff',
                    colorTextMenu: '#dfdfdf',
                    colorTextMenuSecondary: '#dfdfdf',
                    colorTextMenuSelected: '#fff',
                    colorBgMenuItemSelected: '#22272b',
                    colorTextMenuActive: 'rgba(255,255,255,0.85)',
                    colorTextRightActionsItem: '#dfdfdf',
                },
                colorTextAppListIconHover: '#fff',
                colorTextAppListIcon: '#dfdfdf',
                sider: {
                    colorMenuBackground: '#fff',
                    colorMenuItemDivider: '#dfdfdf',
                    colorBgMenuItemHover: '#f6f6f6',
                    colorTextMenu: '#595959',
                    colorTextMenuSelected: '#242424',
                    colorTextMenuActive: '#242424',
                },
            }}
            siderWidth={216}
            bgLayoutImgList={[
                {
                    src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                    left: 85,
                    bottom: 100,
                    height: '303px',
                },
                {
                    src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                    bottom: -68,
                    right: -45,
                    height: '303px',
                },
                {
                    src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
                    bottom: 0,
                    left: 0,
                    width: '331px',
                },
            ]}
            {...defaultProps}
            location={{
                pathname,
            }}
            avatarProps={{
                src: user?.employee?.photo_url,
                size: 'small',
                title: <div style={{ color: '#dfdfdf' }}>{user?.employee.full_name}</div>,
                render: (props, dom) => {
                    return (
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: 'logout',
                                        label: t('logout'),
                                        icon: <LogoutOutlined/>,
                                        onClick: () => {
                                            signOut();

                                            window.location.reload();
                                        },
                                    },
                                ],
                            }}
                        >
                            {dom}
                        </Dropdown>
                    );
                },
            }}
            actionsRender={(props) => {
                // Language picker was here.

                return [];
            }}
            menuItemRender={(item, dom) => {
                return (
                    <Link
                        to={item.path || ''}
                        key={item.key}
                    >
                        {dom}
                    </Link>
                );
            }}
            {...settings}
        >
            <div id="printable-content">
                {children}
            </div>
        </BaseProLayout>
    );
};

export default ProLayout;
