import React from 'react';
import ReactDOM from 'react-dom/client';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './bootstrap';
import { authKey } from './utils/cookie';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const store = createStore({
    authName: authKey,
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: process.env.NODE_ENV === 'production' || window.location.protocol === 'https:',
})

root.render(
    <AuthProvider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
