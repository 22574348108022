import React from 'react';
import { ArrayTable } from '@formily/antd-v5';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

type DeleteContractButtonProps = {
    onClick: (e: any) => void;
    style?: React.CSSProperties;
}

const DeleteContractButton: React.FC<DeleteContractButtonProps> = ({
    onClick,
    ...props
}) => {
    const record = (ArrayTable as any).useRecord();
    const index = (ArrayTable as any).useIndex();

    if (record?.id) {
        return null;
    }

    return (
        <Button
            type="dashed"
            icon={<DeleteOutlined />}
            onClick={() => onClick(index)}
            {...props}
        />
    );
}

export default DeleteContractButton;
