import { EmployeeContractResource } from '../../../../../types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dashable, getDashableDate } from '../utils';
import { useToggle } from 'ahooks';
import { Button, Descriptions } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import ContractHistoryDrawer from '../../components/PersonnelForm/ContractHistoryDrawer';

type ContractCardProps = {
    contract: EmployeeContractResource,
}

const ContractCard: React.FC<ContractCardProps> = ({ contract }) => {
    const { t: ft } = useTranslation('personnel-files', {
        keyPrefix: 'form',
    });
    const { t: ct } = useTranslation('common');
    const dashableDate = getDashableDate(ct);
    const [
        isContractHistoryVisible,
        {
            setLeft: hideContractHistory,
            setRight: showContractHistory,
        },
    ] = useToggle(false);

    return (
        <>
            <Descriptions
                title={ft('contract-information.title') + ': ' + contract.role.name + ' - ' + contract.branch.company.name + ', ' + contract.branch.name}
                extra={
                    <Button
                        type="primary"
                        icon={<HistoryOutlined />}
                        onClick={showContractHistory}
                    >
                        {ft('contract-information.history')}
                    </Button>
                }
            >
                <Descriptions.Item
                    label={ft('contract-information.primary-branch-id')}
                >
                    {dashable(contract.branch.name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.primary-role-id')}
                >
                    {dashable(contract.role?.name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.primary-work-area-id')}
                >
                    {dashable(contract.work_area?.name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.contract-form.title')}
                >
                    {
                        contract.form
                            ? ft(`contract-information.contract-form.options.${contract.form}`)
                            : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.contract-start-date')}
                >
                    {dashableDate(contract.start_date)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.contract-end-date')}
                >
                    {dashableDate(contract.end_date)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-days-per-month-a')}
                >
                    {dashable(contract.work_days_per_month_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-days-per-week-a')}
                >
                    {dashable(contract.work_days_per_week_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-hours-per-month')}
                >
                    {dashable(contract.work_hours_per_month)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-hours-per-day-a')}
                >
                    {dashable(contract.work_hours_per_day_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-hours-per-day-min-a')}
                >
                    {dashable(contract.work_hours_per_day_min_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.work-hours-per-day-max-a')}
                >
                    {dashable(contract.work_hours_per_day_max_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.salary-gross')}
                >
                    €{dashable(contract.salary_gross)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.salary-net')}
                >
                    €{dashable(contract.salary_net)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.tax-class.title')}
                >
                    {dashable(contract?.tax_class)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.social-expense-employer')}
                >
                    €{dashable(contract.social_expense_employer)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.social-expense-employee')}
                >
                    €{dashable(contract.social_expense_employee)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.meal-cost-per-day-a')}
                >
                    €{dashable(contract.meal_cost_per_day_a)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.hourly-rate-gross')}
                >
                    €{dashable(contract.hourly_rate_gross)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('contract-information.hourly-rate-net')}
                >
                    €{dashable(contract.hourly_rate_net)}
                </Descriptions.Item>
            </Descriptions>
            <ContractHistoryDrawer
                onClose={hideContractHistory}
                open={isContractHistoryVisible}
                employeeId={contract.employee_id}
                id={contract.id}
            />
        </>
    );
};

export default ContractCard;
