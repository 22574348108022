import { StartUpTranslations } from '../../types';

const startUp: StartUpTranslations = {
    form: {
        title: 'Start-Up & Company Management',
        'account-name': 'Account Name',
        companies: 'Companies',
        'new-company': 'New Company',
        'add-company': 'Add Company',
        company: {
            form: {
                title: 'Edit Company',
                name: 'Name',
                branches: 'Branches',
                'new-branch': {
                    title: 'New Branch',
                    'payment-methods': {
                        'defaults': {
                            'cash': {
                                'name': 'Cash'
                            }
                        }
                    }
                },
                'add-branch': 'Add Branch',
            },
            branch: {
                title: 'Edit Branch',
                name: 'Name',
                'payment-methods': {
                    title: 'Customer Payment Methods',
                    'add': 'Add Payment Method',
                    'default-name': 'New Payment Method',
                },
                'timezone': 'Timezone',
            },
        },
        'work-areas': 'Work Areas',
        'new-work-area': 'New Work Area',
        'add-work-area': 'Add Work Area',
        roles: 'Roles',
        'add-role': 'Add Role',
        'new-role': 'New Role',
        modified: 'Modified',
    },
};

export default startUp;
