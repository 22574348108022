import { IdentityTranslations } from '../../types';

const identity: IdentityTranslations = {
    account: 'Account',
    logout: 'Logout',
    email: {
        placeholder: 'Please enter your email',
        required: 'Email is required',
    },
    password: {
        placeholder: 'Please enter your password',
        required: 'Password is required',
    },
    idle: {
        prompt: {
            title: 'You will be logged out',
            description: 'You have been inactive for {{idleMinutes}} minutes. You will be logged out soon.',
        },
    },
    forgot: 'Forgot password',
    'forgot-password': {
        'description': 'Enter your email to receive a link to reset your password.',
        button: {
            submit: 'Submit',
        },
        failure: {
            content: 'Something went wrong. Please try again later.',
        },
        success: {
            button: {
                ok: 'OK',
            },
            title: 'Email sent',
            content: 'A link to reset your password has been sent to your email address.',
        },
    },
    'reset-password': {
        'title': 'Reset password',
        password: {
            placeholder: 'Please enter your new password',
            required: 'New password is required',
        },
        'password-confirmation': {
            placeholder: 'Please confirm your new password',
            required: 'Password confirmation is required',
            'mismatch': 'Password confirmation does not match',
        },
        'identity-verification-notice': 'Your identity is verified. Please set a new password for your account.',
        'submit': 'Reset password',
        'success': 'Your password has been reset successfully.',
    },
    remember: 'Remember me',
    rbac: {
        errors: {
            'access-denied': {
                title: 'Access denied',
                subtitle: 'Sorry, you are not authorized to access this page.',
                'hint': 'If you think this is a mistake, please contact your administrator to grant you one of the following permission(s):',
                'actions': {
                    'go-home': 'Back Home',
                },
            },
        },
    },
};

export default identity;
