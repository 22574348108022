import axiosBase from "axios";
import i18n from '../../i18n';
import { message } from 'antd';
import { getTokenData } from '../cookie';

if (!process.env.REACT_APP_BASE_URL) {
    throw new Error("REACT_APP_BASE_URL is not defined");
}

export const baseUrl: string = process.env.REACT_APP_BASE_URL;
const baseURL = `${baseUrl}/api/v1`;

const axios = axiosBase.create({
    baseURL,
    headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        "Accept-Language": i18n.language,
    },
});

const { token, token_type: type } = getTokenData();

if (token) {
    axios.defaults.headers.common.Authorization = `${type || 'Bearer'} ${token}`;
}

axios.interceptors.response.use(
    (r) => r,
    (e) => {
        if (!e.response) {
            message.error(i18n.t('network.error.no-connection'));
        }

        if (e.response && [404, 500, 403].includes(e.response.status)) {
            message.error(i18n.t(`network.error.${e.response.status}`));
        }

        // @todo probably needs to be moved to app level
        // @todo so that we can trigger logout.
        if (e.response && (e.response.status === 401 || e.response.status === 419)) {
            window.location.href = '/identity/login';
        }

        throw e;
    },
);

export default axios;
