const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        border: 1,
        borderStyle: 'solid',
        borderColor: '#d9d9d9',
        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
        marginBottom: 10,
        padding: '3px 6px',
        color: 'rgba(0, 0, 0, 0.88)',
    },
    editButton: {
        color: '#aaa',
        paddingRight: 10,
    },
    deleteButton: {
        color: 'red',
        paddingRight: 0,
    },
    newIcon: {
        color: 'red',
        paddingRight: 5,
    },
};

export default styles;
