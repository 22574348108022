import React from 'react';
import { ArrayTable, FormDialog } from '@formily/antd-v5';
import { Button, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ContractForm from '../ContractForm';
import { useTranslation } from 'react-i18next';

type EditContractButtonProps = {
    onChange: (key: number, values: any) => void;
}

const EditContractButton: React.FC<EditContractButtonProps> = ({ onChange }) => {
    const { t: ct } = useTranslation('common');
    const record = (ArrayTable as any).useRecord();
    const index = (ArrayTable as any).useIndex();

    return (
        <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
                const drawer = FormDialog({
                    width: '90%',
                }, 'edit', ContractForm)

                drawer
                    .forConfirm((payload, next) => {
                        onChange(index, { ...payload.values });

                        message.warning(ct('reminder.main-form-submission'))
                        next(payload)
                    })
                    .open({
                        initialValues: {
                            ...record,
                        }
                    })
            }}
        >
            { ct('edit') }
        </Button>
    );
}

export default EditContractButton;
