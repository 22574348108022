const sections: {
    [key: string]: string;
} = {
    personalInformation: 'personal-information',
    addressInformation: 'address-information',
    passportInformation: 'passport-information',
    taxAndSocialSecurityInformation: 'tax-and-social-security-information',
    paymentInformation: 'payment-information',
    workInformation: 'work-information',
    contractInformation: 'contract-information',
    personalHealthInformation: 'personal-health-information',
    healthPassportInformation: 'health-passport-information',
    emergencyContactInformation: 'emergency-contact-information',
};

export const generateTabList = (t: any) => Object.keys(sections).map((key: string) => (
    {
        title: t(`sections.${sections[key]}`),
        href: `#${sections[key]}`,
        key: sections[key],
    }
));


export default sections;
