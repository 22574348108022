import Cookies from 'js-cookie';

export const authKey = '_auth';

type TokenData = {
    token: string|undefined;
    token_type: string|undefined;
};

export const getTokenData = (): TokenData => ({
    token: Cookies.get(authKey),
    token_type: Cookies.get(`${authKey}_type`),
});
