import React, { useMemo } from 'react';
import {
    ArrayCards,
    ArrayItems,
    ArrayTable, Cascader, Checkbox, DatePicker, Editable, Form,
    FormCollapse,
    FormGrid,
    FormItem,
    FormLayout,
    FormTab,
    Input, NumberPicker, Password, PreviewText, Radio, Reset, Select,
    Space, Submit, Switch, TimePicker, Transfer, TreeSelect, Upload,
} from '@formily/antd-v5';
import { createSchemaField } from '@formily/react';
import { genMoneyProps } from '../../../../../../utils/moneyFormatter';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../components/Text';
import TranslatedText from '../../../../../../components/TranslatedText';
import { Button, Card, Rate, Slider } from 'antd';
import { asyncDataSource, daysPropsCreator, hoursPropsCreator } from '../utils';
import { onFieldReact } from '@formily/core';
import {
    getListBranches,
    getListRoles,
    getListWorkAreas,
} from '../../../../../../utils/network/api/v1';
import { Form as FormType } from '@formily/core';
import { useToggle } from 'ahooks';
import ContractHistoryDrawer from '../ContractHistoryDrawer';

const SchemaField = createSchemaField({
    components: {
        Space,
        FormGrid,
        FormLayout,
        FormTab,
        FormCollapse,
        ArrayTable,
        ArrayItems,
        ArrayCards,
        FormItem,
        DatePicker,
        Checkbox,
        Cascader,
        Editable,
        Input,
        Text,
        TranslatedText,
        NumberPicker,
        Switch,
        Password,
        PreviewText,
        Radio,
        Reset,
        Select,
        Submit,
        TimePicker,
        Transfer,
        TreeSelect,
        Upload,
        Card,
        Slider,
        Rate,
        Button,
    },
});

const ContractForm = (form: FormType) => {
    const { t } = useTranslation('personnel-files');
    const { t: ct } = useTranslation('common');

    const moneyProps = useMemo(() => genMoneyProps(ct), [ct]);
    const daysProps = useMemo(() => daysPropsCreator(ct), [ct]);
    const hoursProps = useMemo(() => hoursPropsCreator(ct), [ct]);

    const [
        isContractHistoryVisible,
        {
            setLeft: hideContractHistory,
            setRight: showContractHistory,
        },
    ] = useToggle(false);

    form.setEffects(() => {
        asyncDataSource('role_id', async () => {
            return await getListRoles().then((data) => {
                return data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            });
        });
        asyncDataSource('branch_id', async () => {
            return await getListBranches().then((data) => {
                return data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            });
        });
        asyncDataSource('work_area_id', async () => {
            return await getListWorkAreas().then((data) => {
                return data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            });
        });
        onFieldReact('form', (field) => {
            form.setFieldState('work_hours_per_month', (state) => {
                // @ts-ignore
                const { value } = field;

                if (value === 'full_time') {
                    state.componentProps = {
                        ...state.componentProps,
                        min: 141,
                        max: 180,
                    };
                } else if (value === 'part_time') {
                    state.componentProps = {
                        ...state.componentProps,
                        min: 44,
                        max: 140,
                    };
                } else if (value === 'working_student') {
                    state.componentProps = {
                        ...state.componentProps,
                        min: 43,
                        max: 80,
                    };
                } else if (value === 'mini_job') {
                    state.componentProps = {
                        ...state.componentProps,
                        min: 1,
                        max: 43,
                    };
                } else {
                    state.componentProps = {
                        ...state.componentProps,
                        min: 0,
                        max: 180,
                    };
                }
            });
        });
        onFieldReact('branch_id', (field) => {
            // @ts-ignore
            const { dataSource, value } = field;

            if (value) {
                form.setValues({
                    branch_name: dataSource?.find(
                        (item: any) => item.value === value,
                    ).label,
                });
            }
        });
        onFieldReact('role_id', (field) => {
            // @ts-ignore
            const { dataSource, value } = field;

            if (value) {
                form.setValues({
                    role_name: dataSource?.find(
                        (item: any) => item.value === value,
                    ).label,
                });
            }
        });
        onFieldReact('work_area_id', (field) => {
            // @ts-ignore
            const { dataSource, value } = field;

            if (value) {
                form.setValues({
                    work_area_name: dataSource?.find(
                        (item: any) => item.value === value,
                    ).label,
                });
            }
        });
    });


    const bonusCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{
                title: t('form.contract-information.bonus-premium.title'),
            }}
            name="lcro75iodpq"
        >
            <SchemaField.Markup
                title={t('form.contract-information.bonus-premium.resource.title')}
                x-decorator="FormItem"
                x-component="Select"
                x-validator={[]}
                x-component-props={{
                    placeholder: ct('input.select.placeholder'),
                    allowClear: true,
                }}
                name="premium_resource"
                enum={[
                    {
                        label: ct('input.select.none'),
                        value: '',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.holding_total'),
                        value: 'holding_total',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.company_total'),
                        value: 'company_total',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.branch_total'),
                        value: 'branch_total',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.personal_total'),
                        value: 'personal_total',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.kitchen_total'),
                        value: 'kitchen_total',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.resource.options.bar_total'),
                        value: 'bar_total',
                    },
                ]}
            />
            <SchemaField.Markup
                title={t('form.contract-information.bonus-premium.gross-net.title')}
                x-decorator="FormItem"
                x-component="Select"
                x-validator={[]}
                x-component-props={{
                    placeholder: ct('input.select.placeholder'),
                }}
                name="premium_gross_net"
                enum={[
                    {
                        label: t('form.contract-information.bonus-premium.gross-net.options.gross'),
                        value: 'gross',
                    },
                    {
                        label: t('form.contract-information.bonus-premium.gross-net.options.net'),
                        value: 'net',
                    },
                ]}
            />
            <SchemaField.Number
                title={t('form.contract-information.bonus-premium.premium-rate')}
                x-decorator="FormItem"
                x-component="NumberPicker"
                x-validator={[]}
                x-component-props={{
                    step: 0.1,
                    precision: 1,
                    decimalSeparator: '.',
                    min: 0,
                    max: 1,
                    addonBefore: '%',
                }}
                default="0.0"
                name="premium_rate"
            />
        </SchemaField.Void>
    );

    const contractInformationCard = (
        <SchemaField.Void
            x-component="Card"
            x-component-props={{
                title: t('form.contract-information.title'),
                extra: (
                    (
                        form.values.id && <Button type="dashed" onClick={showContractHistory}>
                            {t('form.contract-information.history')}
                        </Button>
                    )
                ),
            }}
            x-decorator={undefined}
            name="bnjopsyti7t"
        >
            <SchemaField.Void
                x-component="FormGrid"
                x-validator={[]}
                name="6rovxrlrg4u"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    x-validator={[]}
                    name="vg6u48vz1gd"
                >
                    <SchemaField.String
                        title={t('form.contract-information.contract-form.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                        }}
                        name="form"
                        enum={[
                            {
                                label: t('form.contract-information.contract-form.options.full_time'),
                                value: 'full_time',
                            },
                            {
                                label: t('form.contract-information.contract-form.options.part_time'),
                                value: 'part_time',
                            },
                            {
                                label: t('form.contract-information.contract-form.options.mini_job'),
                                value: 'mini_job',
                            },
                            {
                                label: t('form.contract-information.contract-form.options.working_student'),
                                value: 'working_student',
                            },
                        ]}
                        required
                    />
                    <SchemaField.String
                        title={t('form.contract-information.contract-start-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        name="start_date"
                        x-component-props={{
                            format: ct('input.date.format'),
                        }}
                        required
                    />
                    <SchemaField.String
                        title={t('form.contract-information.contract-end-date')}
                        x-decorator="FormItem"
                        x-component="DatePicker"
                        name="end_date"
                        x-component-props={{
                            format: ct('input.date.format'),
                        }}
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    name="1ftmhtsx9u9"
                >
                    <SchemaField.Markup
                        title={t('form.contract-information.primary-branch-id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="branch_id"
                    />
                    <SchemaField.Markup
                        title={t('form.contract-information.primary-role-id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="role_id"
                    />
                    <SchemaField.Markup
                        title={t('form.contract-information.primary-work-area-id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        x-validator={[]}
                        name="work_area_id"
                    />
                </SchemaField.Void>
            </SchemaField.Void>
            <SchemaField.Void
                x-component="FormGrid"
                x-validator={[]}
                name="jeadr5m4s3k"
            >
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    x-validator={[]}
                    name="4oitiorks8e"
                >
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-month')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-reactions={(field) => {
                            const contractForm = field.query('form').value();
                            // @ts-ignore
                            const value = field.value;
                            let min = 0;
                            let max = 180;

                            if (contractForm === 'full_time') {
                                min = 141;
                                max = 180;
                            } else if (contractForm === 'part_time') {
                                min = 44;
                                max = 140;
                            } else if (contractForm === 'mini_job') {
                                min = 1;
                                max = 43;
                            } else if (contractForm === 'working_student') {
                                min = 43;
                                max = 80;
                            }

                            // @ts-ignore
                            field.selfErrors = (value < min || value > max)
                                ? t('form.contract-information.work-hours-per-month-error', {
                                    min,
                                    max,
                                })
                                : '';
                        }}
                        name="work_hours_per_month"
                        required
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-days-per-week-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            ...daysProps,
                            max: 7,
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="work_days_per_week_a"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-days-per-month-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            ...daysProps,
                            max: 30,
                        }}
                        name="work_days_per_month_a"
                        required
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="work_hours_per_day_a"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-min-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[]}
                        name="work_hours_per_day_min_a"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-max-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[]}
                        name="work_hours_per_day_max_a"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.meal-cost-per-day-a')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[]}
                        name="meal_cost_per_day_a"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.salary-gross')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="salary_gross"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.salary-net')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="salary_net"
                    />
                    <SchemaField.String
                        title={t('form.contract-information.tax-class.title')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                        enum=
                            {['1', '2', '3', '4', '5', 'G'].map((value) => ({
                                label: t(`form.contract-information.tax-class.options.${value}`),
                                value,
                            }))}
                        name="tax_class"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.social-expense-employer')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="social_expense_employer"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.social-expense-employee')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="social_expense_employee"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.hourly-rate-gross')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="hourly_rate_gross"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.hourly-rate-net')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="hourly_rate_net"
                    />
                </SchemaField.Void>
                <SchemaField.Void
                    x-component="FormGrid.GridColumn"
                    x-validator={[]}
                    name="76t9allhpsr"
                >
                    <SchemaField.Number
                        title={t('form.contract-information.salary-fixed')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="salary_fixed"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-days-per-week-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            ...daysProps,
                            max: 7,
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="work_days_per_week_b"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-days-per-month-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                            ...daysProps,
                            max: 30,
                        }}
                        name="work_days_per_month_b"
                        required
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                        name="work_hours_per_day_b"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-min-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[]}
                        name="work_hours_per_day_min_b"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.work-hours-per-day-max-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={hoursProps}
                        x-validator={[]}
                        name="work_hours_per_day_max_b"
                    />
                    <SchemaField.Number
                        title={t('form.contract-information.meal-cost-per-day-b')}
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={moneyProps}
                        x-validator={[]}
                        name="meal_cost_per_day_b"
                    />
                    {bonusCard}
                </SchemaField.Void>
            </SchemaField.Void>
        </SchemaField.Void>
    );

    return (
        <>
            <Form form={form}>
                <FormLayout labelCol={6} wrapperCol={12}>
                    <SchemaField>
                        {contractInformationCard}
                    </SchemaField>
                </FormLayout>
            </Form>
            <ContractHistoryDrawer
                onClose={hideContractHistory}
                open={isContractHistoryVisible}
                employeeId={form.values.employee_id}
                id={form.values.id}
            />
        </>
    );
};

export default ContractForm;
