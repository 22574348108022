import { List } from 'antd';
import { Switch } from '@formily/antd-v5';
import { CrownFilled, ShopFilled } from '@ant-design/icons';
import React from 'react';
import { patchEmployeeUserPermission } from '../../../../../../utils/network/api/v1';
import { EmployeeUserResource, ListedPermission } from '../../../../../../types';
import { useTranslation } from 'react-i18next';

type UserPermissionsProps = {
    data: EmployeeUserResource,
    setData: React.Dispatch<React.SetStateAction<EmployeeUserResource | undefined>>,
    definitions: ListedPermission[];
    busy: boolean,
};

const UserPermissions: React.FC<UserPermissionsProps> = ({
    data,
    setData,
    definitions,
    busy,
}) => {
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'account-management.existing-user',
    });

    const employee = data.data;
    const { user } = employee;

    const [
        loadingPermissions,
        setLoadingPermissions,
    ] = React.useState<string[]>([]);

    return (
        <div style={{ width: 400, borderLeft: '1px solid rgba(5, 5, 5, 0.06)' }}>
            <div
                style={{
                    display: 'flex',
                    flex: 0,
                    alignItems: 'center',
                    padding: '16px 24px',
                    fontSize: 16,
                    lineHeight: 1.5,
                    borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
                }}
            >
                <div
                    style={{
                        flex: 1,
                        margin: 0,
                        color: 'rgba(0, 0, 0, 0.88)',
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: 1.5,
                    }}
                >
                    { t('permissions.title') }
                </div>
            </div>
            <div style={{ padding: '0 16px', overflow: 'auto', height: '90%' }}>
                <List
                    dataSource={definitions}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            actions={[
                                <Switch
                                    key={`sw-${item.id}`}
                                    checked={employee.permissions.includes(item.name)}
                                    loading={loadingPermissions.includes(item.name)}
                                    onChange={(checked) => {
                                        setLoadingPermissions((loadingPermissions) => [
                                            ...loadingPermissions,
                                            item.name,
                                        ]);

                                        patchEmployeeUserPermission(
                                            employee.id,
                                            item.name,
                                            checked,
                                        ).then((res) => {
                                            setData((data) => ({
                                                ...data!,
                                                data: {
                                                    ...data!.data,
                                                    user: {
                                                        ...user!,
                                                    },
                                                    permissions: checked
                                                        ? data!.data.permissions.concat(item.name)
                                                        : data!.data.permissions.filter(
                                                            (p) => p !== item.name,
                                                        ),
                                                },
                                            }));
                                        }).finally(() => {
                                            setLoadingPermissions((loadingPermissions) => loadingPermissions.filter(
                                                (p) => p !== item.name,
                                            ));
                                        });
                                    }}
                                    disabled={!user || busy}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                title={(
                                    <>
                                        {item.type === 'corporational' && (
                                            <CrownFilled
                                                style={{ color: 'palevioletred' }}
                                            />
                                        )}
                                        {item.type === 'managerial' && (
                                            <ShopFilled style={{ color: 'royalblue' }}/>
                                        )}
                                        <a href="https://ant.design/index-cn">{item.t_name}</a>
                                    </>
                                )}
                                description={item.t_description}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default UserPermissions;
