import { PersonnelFilesTranslations } from '../../types';

const personnelFiles: PersonnelFilesTranslations = {
    'index': {
        'full-name': 'Full Name',
        'company': 'Company',
        'role': 'Role',
        'branch': 'Branch',
        'actions': 'Actions',
        'create-personnel-file': 'Create Personnel File',
        'filters': {
            'state': {
                'options': {
                    'all': 'All',
                    'active': 'Active',
                    'inactive': 'Inactive',
                },
            },
        },
    },
    'view': {
        'title': 'Personnel File of {{full_name}}',
        'sections': {
            'personal-information': 'Personal',
            'address-information': 'Address',
            'passport-information': 'Passport',
            'tax-and-social-security-information': 'Tax and Social Security',
            'payment-information': 'Payment',
            'work-information': 'Work',
            'contract-information': 'Contract',
            'personal-health-information': 'Personal & Health',
            'health-passport-information': 'Health Passport',
            'emergency-contact-information': 'Emergency Contact',
        },
        'account-management': {
            'title': 'Account Management',
        },
    },
    'create-personnel-file': {
        'title': 'Create Personnel File',
    },
    'edit-personnel-file': {
        'title': 'Edit Personnel File',
    },
    'form': {
        'submit': {
            'result': {
                'success': 'Personnel File has been saved successfully!',
                'error': 'Something went wrong. Please try again later.',
            },
        },
        'personnel-information': {
            'title': 'Personnel Information',
            'first-name': 'First Name',
            'last-name': 'Last Name',
            'full-name': 'Full Name',
            'birth-name': 'Birth Name',
            'email-address': 'Email',
            'phone-number': 'Phone Number',
        },
        'address-information': {
            'title': 'Address Information',
            'address-street': 'Street',
            'address-number': 'Number',
            'address-postcode': 'Postcode',
            'address-city': 'City',
        },
        'passport-information': {
            'title': 'Passport Information',
            'birth-city': 'Birth City',
            'birth-date': 'Birth Date',
            'birth-country': 'Birth Country',
            'identity-document': {
                'title': 'Document Type',
                'types': {
                    'national-id': 'ID Card',
                    'passport': 'Passport',
                },
            },
            'identity-document-number': 'ID Card/Passport Number',
            'identity-document-expiration-date': 'ID Card/Passport Expiration Date',
            'nationality': 'Nationality',
            'ethnicity': 'Ethnicity',
            'residence-permit': {
                'title': 'Residence Permit',
                'types': {
                    'visa': 'Visa',
                    'tourist-visa': 'Tourist',
                    'work-visa': 'Work Visa',
                    'student-visa': 'Student Visa',
                    'family-reunion-visa': 'Family Reunion Visa',
                    'asylum': 'Asylum',
                    'refugee': 'Refugee',
                    'eu': 'EU',
                    'eu-blue-card': 'EU Blue Card',
                    'permanent-residence': 'Permanent Residence',
                    'citizenship': 'Citizenship',
                },
            },
            'residence-permit-expiration-date': 'Residence Permit Expiration Date',
        },
        'tax-and-social-security-information': {
            'title': 'Tax and Social Security Information',
            'tax-id': 'Tax ID',
            'health-insurance-company-name': 'Health Insurance Company',
            'health-insurance-number': 'Health Insurance Number',
            'social-security-number': 'Social Security Number',
            'marital-status': {
                'title': 'Marital Status',
                'options': {
                    'single': 'Single',
                    'married': 'Married',
                    'divorced': 'Divorced',
                    'widowed': 'Widowed',
                },
            },
            'has-children': {
                'title': 'Has Children?',
                'options': {
                    'yes': 'Yes',
                    'no': 'No',
                },
            },
            'employee-children': {
                title: 'Employee Children',
                name: {
                    placeholder: 'Name',
                },
                birth_date: {
                    placeholder: 'birth_date',
                },
            },
            'children-count': 'Number of Children',
            'child-allowance': 'Child Allowance',
            'other-employment': {
                'title': 'Other Employment?',
                'options': {
                    'yes': 'Yes',
                    'no': 'No',
                },
            },
            'other-employment-type': {
                'title': 'Other Employment Type',
                'options': {
                    'mini-job': 'Mini Job',
                    'subject-to-social-insurance': 'Subject to Social Insurance',
                },
            },
            'other-employment-employer': 'Other Employer',
        },
        'payment-information': {
            'title': 'Payment Information',
            'bank-name': 'Bank Name',
            'bank-iban': 'IBAN',
            'bank-bic': 'BIC',
            'bank-account-holder': 'Account Holder',
            'different-bank-account-holder': 'Different Account Holder:',
        },
        'work-information': {
            'title': 'Work Information',
            'application-date': 'Application Date',
            'application-role-id': 'Application Role',
            'probation-start-date': 'Probation Start Date',
            'probation-branch-id': 'Probation Branch',
            'probation-days': {
                'title': 'Probation Days',
                'options': {
                    '1': '1 Day',
                    '2': '2 Days',
                    '3': '3 Days',
                },
            },
            'start-date': 'Start Date',
            'end-date': 'End Date',
            'personnel-number': 'Personnel Number',
            'has-driving-license': {
                'title': 'Driving License?',
                'options': {
                    'yes': 'Yes',
                    'no': 'No',
                },
            },
            'driving-license-number': 'Driving License Number',
            'tasks': 'Tasks',
            'education-school': 'School Education',
            'work-experience': 'Work Experience',
            'skills': 'Skills',
        },
        'contract-information': {
            'title': 'Contract Information',
            'history': 'History',
            'snapshot-date': 'Snapshot Date',
            'contract-form': {
                'title': 'Contract Form',
                'options': {
                    'full_time': 'Full Time',
                    'part_time': 'Part Time',
                    'mini_job': 'Mini Job',
                    'working_student': 'Working Student',
                },
            },
            'contract-start-date': 'Contract Start Date',
            'contract-end-date': 'Contract End Date',
            'primary-branch-id': 'Branch',
            'primary-role-id': 'Role',
            'primary-work-area-id': 'Work Area',
            'salary-gross': 'Gross Salary',
            'salary-net': 'Net Salary',
            'tax-class': {
                'title': 'Tax Class',
                'options': {
                    '1': 'Class 1',
                    '2': 'Class 2',
                    '3': 'Class 3',
                    '4': 'Class 4',
                    '5': 'Class 5',
                    'G': 'Class G',
                },
            },
            'social-expense-employer': 'Employer Social Expense',
            'social-expense-employee': 'Employee Social Expense',
            'work-hours-per-month': 'Work Hours Per Month',
            'work-hours-per-month-error': 'Work Hours Per Month must be between {{min}} and {{max}}.',
            'hourly-rate-gross': 'Gross Hourly Rate',
            'hourly-rate-net': 'Net Hourly Rate',
            'work-hours-per-day-a': 'Work Hours Per Day',
            'work-hours-per-day-min-a': 'Min Work Hours Per Day',
            'work-hours-per-day-max-a': 'Max Work Hours Per Day ',
            'work-days-per-week-a': 'Work Days Per Week',
            'work-days-per-month-a': 'Work Days Per Month',
            'work-hours-per-day-b': 'Work Hours Per Day',
            'work-hours-per-day-min-b': 'Min Work Hours Per Day',
            'work-hours-per-day-max-b': 'Max Work Hours Per Day',
            'work-days-per-week-b': 'Work Days Per Week',
            'work-days-per-month-b': 'Work Days Per Month',
            'salary-fixed': 'Fixed Salary',
            'meal-cost-per-day-a': 'Meal Cost Per Day',
            'meal-cost-per-day-b': 'Meal Cost Per Day',
            'bonus-premium': {
                'title': 'Bonus Premium',
                'resource': {
                    'title': 'Resource',
                    'options': {
                        'holding_total': 'Holding Total',
                        'company_total': 'Company Total',
                        'branch_total': 'Branch Total',
                        'personal_total': 'Personal Total',
                        'kitchen_total': 'Kitchen Total',
                        'bar_total': 'Bar Total',
                    },
                },
                'gross-net': {
                    'title': 'Gross/Net',
                    'options': {
                        'gross': 'Gross',
                        'net': 'Net',
                    },
                },
                'premium-rate': 'Premium Rate',
            },
        },
        'personal-health-information': {
            'title': 'Personal & Health Information',
            'hobbies': 'Hobbies',
            'height': 'Height',
            'weight': 'Weight',
            'blood-type': 'Blood Type',
            'diseases': 'Diseases',
            'medications': 'Medications',
            'addictions': 'Addictions',
        },
        'health-passport-information': {
            'title': 'Health Passport Information',
            'has-health-passport': 'Has Health Passport?',
            'health-passport-issue-date': 'Health Passport Issue Date',
            'health-passport-expiration-date': 'Health Passport Expiry Date',
        },
        'emergency-contact-information': {
            'title': 'Emergency Contact Information',
            'name': 'Name',
            'relationship': 'Relationship',
            'phone-number': 'Phone Number',
            'email-address': 'Email Address',
            'address': 'Address',
        },
        'sections': {
            'personal-information': 'Personal',
            'address-information': 'Address',
            'passport-information': 'Passport',
            'tax-and-social-security-information': 'Tax and Social Security',
            'payment-information': 'Payment',
            'work-information': 'Work',
            'contract-information': 'Contract',
            'personal-health-information': 'Personal & Health',
            'health-passport-information': 'Health Passport',
            'emergency-contact-information': 'Emergency Contact',
        },
    },
    'account-management': {
        'new-user': {
            'description': 'This employee does not have a user account yet. You can create one for them by clicking the "Create User" button.',
            'create-user': {
                'description': 'To create a user account for this employee, click <b>Create User</b>.',
                'note-1': 'The username will be the employee\'s email address.',
                'note-2': 'A password will be sent to this email address.',
                'note-3': 'You can edit the access rights after creating the account.',
                'action': {
                    'text': 'Create User',
                },
            },
        },
        'existing-user': {
            'description': 'This employee has a user account that they can use to log in.',
            'send-password': {
                'title': 'Send Password',
                'action': {
                    'text': 'Passwort Senden',
                },
                'success': {
                    'description': 'The password has been set to <b>{{password}}</b> and sent to the employee\'s email address.',
                    'note': '<b>Hinweis</b>: Dieses Passwort wird nur einmal angezeigt.',
                },
                'error': 'The password could not be sent. Please try again.',
            },
            'delete-user': {
                'action': {
                    'text': 'Delete User',
                },
                'error': 'The user could not be deleted. Please try again.',
            },
            'permissions': {
                'title': 'Permissions',
            },
        },
    },
};

export default personnelFiles;
