import { DashboardTranslations } from '../../types'

const dashboard: DashboardTranslations = {
    'upcoming-birthdays': {
        title: 'Kommende Geburtstage',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Kommende Passport Ablaufdaten',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Kommende Visaablaufdaten',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Kommende Vertragsablaufdaten',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Kommende Aufenthaltserlaubnisablaufdaten',
    },
};

export default dashboard;
