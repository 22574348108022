import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from './Text';

type TranslatedTextProps = {
    value?: string,
    ns: string,
    options?: object,
    mode?: 'normal' | 'h1' | 'h2' | 'h3' | 'p',
    empty?: React.ReactElement<any, any> | string | null,
};

const TranslatedText: React.FC<TranslatedTextProps> = ({
    value,
    ns,
    options = {},
    mode,
    empty = null,
}) => {
    const { t } = useTranslation(ns, options);

    if (value) {
        return <Text value={t(value)} mode={mode} />;
    }

    if (!empty || typeof empty === 'string') {
        return <Text value="-" mode={mode} />;
    }

    return empty;
}

export default TranslatedText;
