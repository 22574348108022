import React, { useEffect } from 'react';
import { Select } from 'antd';
import { getListBranches } from '../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';

type BranchFilterSelectProps = {
    company_id?: number,
    value?: any,
    onChange?: any,
}

const BranchFilterSelect: React.FC<BranchFilterSelectProps> = ({
    company_id,
    value,
    onChange,
}) => {
    const { t: ct } = useTranslation('common');

    const [
        options,
        setOptions,
    ] = React.useState<Array<{ label: string, value: string }>>([]);

    useEffect(() => {
        getListBranches(company_id).then((res) => {
            setOptions([
                { label: 'All', value: '' },
                ...res.data.map((data: any) => ({
                    value: data.id,
                    label: data.name,
                })),
            ]);
        });
        setOptions([]);
    }, [company_id]);

    return (
         <Select
             options={options}
             value={value}
             onChange={onChange}
             placeholder={ct('input.select.placeholder')}
         />
    );
}

export default BranchFilterSelect;
