import React from 'react';
import CompanyForm from '../components/CompanyForm';
import { StartupCompany } from '../../../../types';

type EditCompanyFormProps = {
    companyId?: number;
    buttons: (onSubmit: (id: StartupCompany) => Promise<StartupCompany>) => React.ReactNode;
}

const EditCompanyForm: React.FC<EditCompanyFormProps> = ({ companyId, buttons }) => {
    return <CompanyForm companyId={companyId} buttons={buttons} />;
};

export default EditCompanyForm;
