import React from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSchemaField } from '@formily/react';
import {
    FormItem,
    FormLayout,
    Input,
    ArrayItems,
    Space,
} from '@formily/antd-v5';
import { StartupCompany } from '../../../../../types';
import { upsertStartupCompany } from '../../../../../utils/network/api/v1';
import BranchItem from './BranchItem';

type BranchFormProps = {
    companyId?: number;
    buttons: (onSubmit: (data: StartupCompany) => Promise<StartupCompany>) => React.ReactNode;
}

const SchemaField = createSchemaField({
    components: {
        FormItem,
        Input,
        ArrayItems,
        Space,
        BranchItem,
    },
});

const CompanyForm: React.FC<BranchFormProps> = ({ companyId, buttons }) => {
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');

    return (
        <FormLayout labelCol={6} wrapperCol={18}>
            <SchemaField>
                <SchemaField.String
                    name="name"
                    title={t('form.company.form.name')}
                    x-decorator="FormItem"
                    x-component="Input"
                    x-validator={[
                        {
                            required: true,
                            message: ct('validation.required'),
                        },
                    ]}
                />
                <SchemaField.Array
                    name="branches"
                    title={t('form.company.form.branches')}
                    x-decorator="FormItem"
                    x-component="ArrayItems"
                    x-validator={[{
                        required: true,
                        message: ct('validation.required'),
                    }]}
                >
                    <SchemaField.Object x-component="BranchItem"/>
                    <SchemaField.Void
                        x-component="ArrayItems.Addition"
                        x-component-props={{
                            defaultValue: {
                                name: t('form.company.form.new-branch.title'),
                                company_id: companyId,
                                payment_methods: [{
                                    name: t('form.company.form.new-branch.payment-methods.defaults.cash.name'),
                                }],
                                timezone: 'UTC+1',
                            },
                        }}
                        title={t('form.company.form.add-branch')}
                    />
                </SchemaField.Array>
            </SchemaField>
            {
                buttons((data: StartupCompany) => (
                    upsertStartupCompany(data).then((res) => {
                        message.success(ct('message.success'));

                        return res.data;
                    }).catch((error) => {
                        message.error('message.error');

                        throw error;
                    })
                ))
            }
        </FormLayout>
    );
};

export default CompanyForm;
