import {
    FieldDataSource,
    FormPathPattern,
    GeneralField,
    onFieldInit,
    onFieldReact,
} from '@formily/core';
import { action, observable } from '@formily/reactive';
import { baseUrl } from '../../../../../utils/network/axios';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import { AxiosProgressEvent, AxiosPromise } from 'axios';
import { ListedData } from '../../../../../types';

export const fixDockerHost = (url: string): string =>
    url?.replace('http://host.docker.internal', baseUrl!);

export const customUploadRequest = (
    request: (
        file: File,
        config: { onUploadProgress: (e: AxiosProgressEvent) => void },
    ) => Promise<any>,
) => async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const config = {
        onUploadProgress: (e: AxiosProgressEvent) => {
            const { loaded, total } = e;
            const percent = total ? Math.round((loaded * 100) / total) : 0;

            if (percent !== 100) {
                onProgress({ percent });
            }
        },
    };

    try {
        const result = await request(file, config);

        onSuccess(result);
    } catch (err) {
        onError({ err });
    }
};

export const validateUploadedPhoto = (file: RcFile): Promise<any> => {
    const isFileTypeOk = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isFileTypeOk) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isFileSizeOk = file.size / 1024 / 1024 < 20;
    if (!isFileSizeOk) {
        message.error('Image must smaller than 20MB!');
    }

    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result as string;
            img.onload = () => {
                // Resize the image
                const maxWidth = 1200;
                const maxHeight = 1600;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(img, 0, 0, width, height);

                // Change the quality of the image
                const quality = 0.8;

                canvas.toBlob(
                    (result) => resolve(result as any),
                    'image/jpeg',
                    quality,
                );
            };
        };
    });
};

export const asyncDataSource = (
    pattern: FormPathPattern,
    service: (field: GeneralField) => Promise<{ label: string; value: any }[]>,
) => {
    onFieldReact(pattern, (field) => {
        // @ts-ignore
        field.loading = true;
        service(field).then(
            // @ts-ignore
            action.bound((data) => {
                // @ts-ignore
                field.dataSource = data;
                // @ts-ignore
                field.loading = false;
            }),
        );
    });
};

export const asyncDataSourceWithLookup = (
    pattern: FormPathPattern,
    service: (param: {
        keyword: string
        field: GeneralField
    }) => Promise<FieldDataSource>,
) => {
    const keyword = observable.ref('');

    onFieldInit(pattern, (field) => {
        field.setComponentProps({
            onSearch: (value: string) => {
                keyword.value = value;
            },
        });
    });

    onFieldReact(pattern, (field: GeneralField) => {
        // @ts-ignore
        field.loading = true;
        service({ field, keyword: keyword.value }).then(
            action.bound?.((data) => {
                // @ts-ignore
                field.dataSource = data;
                // @ts-ignore
                field.loading = false;
            }),
        );
    });
};

export const maritalStatusTypes = (t: Function) => [
    {
        label: t('form.tax-and-social-security-information.marital-status.options.single'),
        value: 'single',
    },
    {
        label: t('form.tax-and-social-security-information.marital-status.options.married'),
        value: 'married',
    },
    {
        label: t('form.tax-and-social-security-information.marital-status.options.divorced'),
        value: 'divorced',
    },
    {
        label: t('form.tax-and-social-security-information.marital-status.options.widowed'),
        value: 'widowed',
    },
];

export const otherEmploymentTypes = (t: Function) => [
    {
        label: t('form.tax-and-social-security-information.other-employment-type.options.mini-job'),
        value: 'mini-job',
    },
    {
        label: t('form.tax-and-social-security-information.other-employment-type.options.subject-to-social-insurance'),
        value: 'subject-to-social-insurance',
    },
];

export const daysPropsCreator = (ct: Function) => ({
    placeholder: ct('input.text.placeholder'),
    step: 1,
    precision: 0,
    min: 0,
});

export const hoursPropsCreator = (ct: Function) => ({
    placeholder: ct('input.text.placeholder'),
    step: 0.5,
    precision: 1,
    min: 0,
    max: 24,
});

export const getAsyncDataSourceWithLookupHandler = (axiosPromise: (keyword: any) => AxiosPromise<ListedData[]>) =>
    async ({ keyword }: { keyword: any }) => {
        return new Promise<FieldDataSource>((resolve) => {
            axiosPromise(keyword).then((data) => {
                resolve(data.data.map((item) => ({
                    label: item.name,
                    value: item.name,
                })));
            });
        });
    };
