import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGB from 'antd/locale/en_GB';
import deDE from 'antd/locale/de_DE';
import trTR from 'antd/locale/tr_TR';
import de from './de';
import en from './en';
import tr from './tr';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Locale } from 'antd/es/locale';

dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('de');

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'de',
        defaultNS: 'common',
        missingKeyNoValueFallbackToKey: true,
        interpolation: {
            escapeValue: false,
        },
        resources: { de, en, tr }
    });
    
export const antdLocales: {[key: string]: Locale} = {
    'de': deDE,
    'en': enGB,
    'tr': trTR,
    'en-US': enGB,
    'en-GB': enGB,
    'de-DE': deDE,
    'tr-TR': trTR,
};

export const defaultLanguage = 'de';

export default i18n;
