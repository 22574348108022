import common from './common';
import identity from './modules/identity';
import { i18nLanguage } from '../types';
import dashboard from './modules/dashboard';
import personnelFiles from './modules/personnelFiles';
import startUp from './modules/startUp';
import personnelPlanning from './modules/personnelPlanning';

const tr: i18nLanguage = {
    common,
    identity,
    dashboard,
    'personnel-files': personnelFiles,
    'personnel-planning': personnelPlanning,
    'start-up': startUp,
};

export default tr;
