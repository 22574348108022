import React, { useEffect } from 'react';
import { getEmployee, getEmployeePdf } from '../../../../utils/network/api/v1';
import { useRequest, useScroll } from 'ahooks';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Anchor, Descriptions, Dropdown,  } from 'antd';
import { DownloadOutlined, EditOutlined, UserOutlined, PrinterOutlined } from '@ant-design/icons';
import { PageContainer, ProCard, WaterMark } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import sections, { generateTabList } from './sections';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { dashable, getDashableDate } from './utils';
import './personnelDetails.css';
import TitleCard from './TitleCard';
import UserModal from './UserModal';
import queryString from 'query-string';
import ContractCard from './ContractCard/ContractCard';
import './printer.css';
import { LoginResponse } from '../../../../utils/network/api/v1/types';

const PersonnelDetails = () => {
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const [, setSearchParams] = useSearchParams();

    const isUserModalOpen = parsed['user-modal'] === '1';
    const navigate = useNavigate();
    const user = useAuthUser() as LoginResponse['user'] || null;
    const id = Number(useParams().id);
    const { data, loading } = useRequest(() => getEmployee(id));
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'view',
    });
    const { t: ft } = useTranslation('personnel-files', {
        keyPrefix: 'form',
    });
    const { t: ct } = useTranslation('common');
    const dashableDate = getDashableDate(ct);
    const tabList = generateTabList(t);
    const personnel = data?.data;

    const scroll = useScroll(document);

    const titleMode = scroll?.top ? 'compact' : 'default';

    let title = loading
        ? `${ct('loading')} #${id}`
        : <TitleCard personnel={personnel} mode={titleMode}/>;

    useEffect(() => {
        let fullname = personnel?.full_name || ct('loading');

        document.title = `PatronX OPDS - ${fullname}`;
    }, [ct, personnel?.full_name]);

    const printableTitle = (
        <ProCard
            className="printer-only"
        >
            <TitleCard personnel={personnel} mode="default" />
        </ProCard>
    );

    const personalInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            loading={loading}
            id={sections.personalInformation}
        >
            <Descriptions title={ft('personnel-information.title')}>
                <Descriptions.Item
                    label={ft('personnel-information.full-name')}
                >
                    {personnel?.full_name}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personnel-information.birth-name')}
                >
                    {dashable(personnel?.birth_name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personnel-information.phone-number')}
                >
                    <a href={`tel:${personnel?.phone_number}`}>
                        {personnel?.phone_number}
                    </a>
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personnel-information.email-address')}
                >
                    <a href={`mailto:${personnel?.email_address}`}>
                        {personnel?.email_address}
                    </a>
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const addressInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.addressInformation}
        >
            <Descriptions title={ft('address-information.title')}>
                <Descriptions.Item
                    label={ft('address-information.address-street')}
                >
                    {dashable(personnel?.address_street)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('address-information.address-number')}
                >
                    {dashable(personnel?.address_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('address-information.address-postcode')}
                >
                    {dashable(personnel?.address_postcode)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('address-information.address-city')}
                >
                    {dashable(personnel?.address_city)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const passportInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.passportInformation}
        >
            <Descriptions title={ft('passport-information.title')}>
                <Descriptions.Item
                    label={ft('passport-information.birth-date')}
                >
                    {dashableDate(personnel?.birth_date)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.birth-city')}
                >
                    {dashable(personnel?.birth_city)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.birth-country')}
                >
                    {dashable(personnel?.birth_country)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.nationality')}
                >
                    {dashable(personnel?.nationality)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.ethnicity')}
                >
                    {dashable(personnel?.ethnicity)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.residence-permit.title')}
                >
                    {dashable(
                        personnel?.residence_permit
                            ? ft(`passport-information.residence-permit.types.${personnel?.residence_permit}`)
                            : null,
                    )}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.residence-permit-expiration-date')}
                >
                    {dashableDate(personnel?.residence_permit_expiration_date)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.identity-document.title')}
                >
                    {dashable(personnel?.identity_document_type)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.identity-document-number')}
                >
                    {dashable(personnel?.identity_document_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('passport-information.identity-document-expiration-date')}
                >
                    {dashableDate(personnel?.identity_document_expiration_date)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const taxAndSocialSecurityInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.taxAndSocialSecurityInformation}
        >
            <Descriptions title={ft('tax-and-social-security-information.title')}>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.tax-id')}
                >
                    {dashable(personnel?.tax_id)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.marital-status.title')}
                >
                    {
                        personnel?.marital_status
                            ? ft(`tax-and-social-security-information.marital-status.options.${personnel.marital_status}`)
                            : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.has-children.title')}
                >
                    {personnel?.has_children ? ct('yes') : ct('no')}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.children-count')}
                >
                    {dashable(personnel?.children_count)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.child-allowance')}
                >
                    {dashable(personnel?.child_allowance)}
                </Descriptions.Item>
                {personnel?.children_count && (
                    <Descriptions.Item
                        label={ft('tax-and-social-security-information.employee-children.title')}
                    >
                        {personnel?.employee_children.map((child) => (
                            child.name + ' (' + dashableDate(child.birth_date) + ')'
                        )).join(', ') || '-'}
                    </Descriptions.Item>
                )}
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.health-insurance-company-name')}
                >
                    {dashable(personnel?.health_insurance_company_name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.health-insurance-number')}
                >
                    {dashable(personnel?.health_insurance_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.social-security-number')}
                >
                    {dashable(personnel?.social_security_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('tax-and-social-security-information.other-employment.title')}
                >
                    {personnel?.other_employment ? ct('yes') : ct('no')}
                </Descriptions.Item>
                {personnel?.other_employment_type && (
                    <Descriptions.Item
                        label={ft('tax-and-social-security-information.other-employment-type.title')}
                    >
                        {ft(`tax-and-social-security-information.other-employment-type.options.${personnel?.other_employment_type}`)}
                    </Descriptions.Item>)
                }
                {personnel?.other_employment_employer && (
                    <Descriptions.Item
                        label={ft('tax-and-social-security-information.other-employment-employer')}
                    >
                        {dashable(personnel?.other_employment_employer)}
                    </Descriptions.Item>
                )}
            </Descriptions>
        </ProCard>
    );

    const paymentInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.paymentInformation}
        >
            <Descriptions title={ft('payment-information.title')}>
                <Descriptions.Item
                    label={ft('payment-information.bank-account-holder')}
                >
                    {dashable(personnel?.bank_account_holder)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('payment-information.bank-name')}
                >
                    {dashable(personnel?.bank_name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('payment-information.bank-iban')}
                >
                    {dashable(personnel?.bank_iban)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('payment-information.bank-bic')}
                >
                    {dashable(personnel?.bank_bic)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('payment-information.different-bank-account-holder')}
                >
                    {dashable(personnel?.different_bank_account_holder)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const workInformation = (
        <ProCard
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.workInformation}
        >
            <Descriptions title={ft('work-information.title')}>
                <Descriptions.Item
                    label={ft('work-information.application-date')}
                >
                    {dashable(dashableDate(personnel?.application_date))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.application-role-id')}
                >
                    {dashable(personnel?.application_role?.name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.probation-branch-id')}
                >
                    {dashable(personnel?.probation_branch?.name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.probation-start-date')}
                >
                    {dashable(dashableDate(personnel?.probation_start_date))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.probation-days.title')}
                >
                    {dashable(personnel?.probation_day_count)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.probation-days.title')}
                >
                    {dashable(personnel?.probation_days.map((day) => (
                        dashableDate(day.date)
                    )).join(', '))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.start-date')}
                >
                    {dashable(dashableDate(personnel?.start_date))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.end-date')}
                >
                    {dashable(dashableDate(personnel?.end_date))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.personnel-number')}
                >
                    {dashable(personnel?.personnel_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.has-driving-license.title')}
                >
                    {dashable(personnel?.has_driving_license ? ct('yes') : ct('no'))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.driving-license-number')}
                >
                    {dashable(personnel?.driving_license_number)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.education-school')}
                >
                    {dashable(personnel?.education_school?.join(', '))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.tasks')}
                >
                    {dashable(personnel?.tasks)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.skills')}
                >
                    {dashable(personnel?.skills.join(', '))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('work-information.work-experience')}
                >
                    {dashable(personnel?.work_experience)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const contractInformation = (
        <WaterMark content={user!.employee.full_name} gapY={120}>
            <ProCard
                gutter={8}
                style={{ minHeight: 200 }}
                id={sections.contractInformation}
            >
                {personnel?.contracts?.map((contract, i) => (
                    <React.Fragment key={contract.id}>
                        <ContractCard contract={contract} />
                        {i !== (personnel.contracts.length - 1) && (
                            <hr style={{ opacity: .2 }} key={`hr-${contract.id}`} />
                        )}
                    </React.Fragment>
                ))}
            </ProCard>
        </WaterMark>
    );

    const personalHealthInformation = (
        <ProCard
            title={ft('personal-health-information.title')}
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.personalHealthInformation}
        >
            <Descriptions>
                <Descriptions.Item
                    label={ft('personal-health-information.hobbies')}
                >
                    {dashable(personnel?.hobbies)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.weight')}
                >
                    {dashable(personnel?.health_weight ? `${personnel?.health_weight}kg` : undefined)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.height')}
                >
                    {dashable(personnel?.health_height ? `${personnel?.health_height}cm` : undefined)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.blood-type')}
                >
                    {dashable(personnel?.health_blood_type)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.diseases')}
                >
                    {dashable(personnel?.health_diseases?.join(', '))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.medications')}
                >
                    {dashable(personnel?.health_medications?.join(', '))}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('personal-health-information.addictions')}
                >
                    {dashable(personnel?.health_addictions?.join(', '))}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const healthInsuranceInformation = (
        <ProCard
            title={ft('health-passport-information.title')}
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.healthPassportInformation}
        >
            <Descriptions>
                <Descriptions.Item
                    label={ft('health-passport-information.has-health-passport')}
                >
                    {personnel?.has_health_passport ? ct('yes') : ct('no')}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('health-passport-information.health-passport-issue-date')}
                >
                    {dashableDate(personnel?.health_passport_issue_date)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('health-passport-information.health-passport-expiration-date')}
                >
                    {dashableDate(personnel?.health_passport_expiration_date)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    const emergencyContactInformation = (
        <ProCard
            title={ft('emergency-contact-information.title')}
            gutter={8}
            style={{ minHeight: 200 }}
            id={sections.emergencyContactInformation}
        >
            <Descriptions>
                <Descriptions.Item
                    label={ft('emergency-contact-information.name')}
                >
                    {dashable(personnel?.emergency_contact_name)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('emergency-contact-information.relationship')}
                >
                    {dashable(personnel?.emergency_contact_relationship)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('emergency-contact-information.phone-number')}
                >
                    {
                        dashable(
                            personnel?.emergency_contact_phone_number
                                ? (
                                    <a href={`tel:${personnel?.emergency_contact_phone_number}`}>
                                        {personnel.emergency_contact_phone_number}
                                    </a>
                                ) : undefined,
                        )
                    }
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('emergency-contact-information.email-address')}
                >
                    {
                        dashable(
                            personnel?.emergency_contact_email_address
                                ? (
                                    <a href={`mailto:${personnel?.emergency_contact_email_address}`}>
                                        {personnel.emergency_contact_email_address}
                                    </a>
                                ) : undefined,
                        )
                    }
                </Descriptions.Item>
                <Descriptions.Item
                    label={ft('emergency-contact-information.address')}
                >
                    {dashable(personnel?.emergency_contact_address)}
                </Descriptions.Item>
            </Descriptions>
        </ProCard>
    );

    return (
        <PageContainer
            title={title}
            loading={loading}
            fixedHeader={true}
            extra={[
                (
                    personnel && (
                        <Dropdown.Button
                            className="dont-print"
                            type="primary"
                            onClick={() => navigate(`/files/personnel/${personnel!.id}/edit`)}
                            menu={{
                                items: [
                                    {
                                        key: 'btnDownloadPdf',
                                        label: ct('download.pdf'),
                                        icon: <DownloadOutlined />,
                                    },
                                    {
                                        key: 'btnEdit',
                                        label: ct('edit'),
                                        icon: <EditOutlined />,
                                    },
                                    {
                                        key: 'btnUser',
                                        label: t('account-management.title'),
                                        icon: <UserOutlined />,
                                    },
                                    {
                                        key: 'btnPrint',
                                        label: ct('print'),
                                        icon: <PrinterOutlined />,
                                    }
                                ],
                                onClick: ({ key }) => {
                                    if (key === 'btnDownloadPdf') {
                                        getEmployeePdf(personnel!.id);

                                        return;
                                    }

                                    if (key === 'btnEdit') {
                                        navigate(`/files/personnel/${personnel!.id}/edit`);

                                        return;
                                    }

                                    if (key === 'btnUser') {
                                        setSearchParams({
                                            'user-modal': '1',
                                        })

                                        return;
                                    }

                                    if (key === 'btnPrint') {
                                        window.print();

                                        return;
                                    }

                                    throw new Error(`Unknown key: ${key}`);
                                }
                            }}
                        >
                            <EditOutlined />
                            {ct('edit')}
                        </Dropdown.Button>
                    )
                ),
            ]}
            affixProps={{
                offsetTop: 56,
            }}
            header={{
                footer: (
                    <Anchor
                        className="dont-print"
                        targetOffset={56 + (titleMode === 'default' ? 248 : 148) + 66}
                        direction="horizontal"
                        items={tabList}
                    />
                ),
            }}
        >
            {personnel && (
                <ProCard
                    direction="column"
                    ghost
                    gutter={[0, 16]}
                >
                    {printableTitle}
                    {personalInformation}
                    {addressInformation}
                    {passportInformation}
                    {taxAndSocialSecurityInformation}
                    {paymentInformation}
                    {workInformation}
                    {contractInformation}
                    {personalHealthInformation}
                    {healthInsuranceInformation}
                    {emergencyContactInformation}
                </ProCard>
            )}

            { personnel && isUserModalOpen && (
                <UserModal
                    personnelId={personnel.id}
                    isOpen={isUserModalOpen}
                    onCancel={() => setSearchParams({})}
                    onClose={() => setSearchParams({})}
                />
            )}
        </PageContainer>
    );
};

export default PersonnelDetails;
