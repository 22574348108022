import { PersonnelPlanningTranslations } from '../../types';

const personnelPlanning: PersonnelPlanningTranslations = {
    'role': 'Role',
    'time': 'Time',
    'starter-hint': 'Please select a branch to start planning the week.',
    'edit': 'Edit',
    'duplicate': 'Duplicate',
    'one-line-alert-message': 'You can only add one row at a time.',
    'delete-popconfirm-message': 'Are you sure you want to delete this row?',
    'personnel-select': {
        'preview-hint': 'Please click on the ⓘ icon to preview the employee.',
        'option-groups': {
            'search-results': {
                'title': 'Search results',
            },
            'previously-selected-results': {
                'title': 'Previously selected results',
            },
        },
        'start-typing-to-search': 'Start typing to search...',
    },
    'preview': {
        'title': 'Personnel Planning Preview',
    },
    'duplicate-week-modal': {
        'title': 'Duplicate Weekly Planning',
        'form': {
            'from': 'From',
            'to': 'To',
            'branch': 'Branch',
            'week': 'Week',
            result: {
                success: 'The weekly planning has been duplicated successfully.',
                error: 'The weekly planning could not be duplicated.',
            },
        },
    },
};

export default personnelPlanning;
