import React from 'react';
import { Col, Row } from 'antd';
import UpcomingBirthdays from './components/UpcomingBirthdays';
import UpcomingPassportExpirationDates from './components/UpcomingPassportExpirationDates';
import UpcomingContractExpirationDates from './components/UpcomingContractExpirationDates';
import UpcomingResidencePermitExpirationDates from './components/UpcomingResidencePermitExpirationDates';
import { PageContainer } from '@ant-design/pro-components';
import { usePermissions } from '../../../utils/hooks';

const Analysis = () => {
    const permissions = usePermissions();

    return (
        <PageContainer title="Dashboard">
            <Row gutter={24}>
                <Col xs={24} lg={6}>
                    <UpcomingBirthdays/>
                </Col>
                { permissions.includes('employee-files:access') && (
                    <Col xs={24} lg={6}>
                        <UpcomingPassportExpirationDates />
                    </Col>
                )}
                { permissions.includes('employee-files:access') && (
                    <Col xs={24} lg={6}>
                        <UpcomingContractExpirationDates />
                    </Col>
                )}
                { permissions.includes('employee-files:access') && (
                    <Col xs={24} lg={6}>
                        <UpcomingResidencePermitExpirationDates />
                    </Col>
                )}
            </Row>
        </PageContainer>
    );
};

export default Analysis;
