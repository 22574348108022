import React from 'react';
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { notification } from 'antd';

type RequiresAuthenticationProps = {
    children: any;
};

const idleTimeout = 10 * 60 * 1000;
const promptBeforeIdle = 1 * 60 * 1000;

const Auth: React.FC<RequiresAuthenticationProps> = ({ children }) => {
    const signOut = useSignOut()
    const { t } = useTranslation('identity');

    const openIdleNotification = () => {
        const idleMinutes = getIdleTime() / 1000 / 60;

        api.info({
            message: t('idle.prompt.title', { idleMinutes }),
            description: t('idle.prompt.description'),
            placement: 'topRight',
            duration: promptBeforeIdle,
        });
    };

    const onAction = () => {
        reset();
        api.destroy();
    };

    const onPrompt = openIdleNotification;

    const { getIdleTime, reset } = useIdleTimer({
        onIdle: () => {
            signOut();

            window.location.reload();
        },
        onPrompt,
        onAction,
        promptBeforeIdle,
        timeout: idleTimeout,
        throttle: 500,
    });

    const [api, contextHolder] = notification.useNotification();

    return (
        <>
            {contextHolder}
            <RequireAuth fallbackPath="/identity/login">
                {children}
            </RequireAuth>
        </>
    );
};

export default Auth;
