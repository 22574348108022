export const residencePermitTypes = (t: any) => [
    {
        title: t('form.passport-information.residence-permit.types.visa'),
        value: 'visa',
        selectable: false,
        children: [
            {
                title: t('form.passport-information.residence-permit.types.tourist-visa'),
                value: 'tourist-visa',
            },
            {
                title: t('form.passport-information.residence-permit.types.work-visa'),
                value: 'work-visa',
            },
            {
                title: t('form.passport-information.residence-permit.types.student-visa'),
                value: 'student-visa',
            },
            {
                title: t('form.passport-information.residence-permit.types.family-reunion-visa'),
                value: 'family-reunion-visa',
            },
        ],
    },
    {
        title: t('form.passport-information.residence-permit.types.citizenship'),
        value: 'citizenship',
    },
    {
        title: t('form.passport-information.residence-permit.types.eu'),
        value: 'eu',
    },
    {
        title: t('form.passport-information.residence-permit.types.permanent-residence'),
        value: 'permanent-residence',
    },
    {
        title: t('form.passport-information.residence-permit.types.asylum'),
        value: 'asylum',
    },
    {
        title: t('form.passport-information.residence-permit.types.refugee'),
        value: 'refugee',
    },
];
