import React, { useCallback, useMemo } from 'react';
import { Alert, Anchor, Button, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { PageContainer } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { EyeOutlined } from '@ant-design/icons';
import PersonnelForm from '../components/PersonnelForm';
import { editEmployee, updateEmployee } from '../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';
import { generateTabList } from '../NewPersonnelForm/sections';
import './styles.module.scss';

const EditPersonnelForm: React.FC = () => {
    const navigate = useNavigate();
    const id = Number(useParams().id);
    const {
        data,
        loading,
    } = useRequest(() => editEmployee(id), { refreshDeps: [id] });
    const { t } = useTranslation('personnel-files');
    const { t: ct } = useTranslation('common');
    const tabList = generateTabList(t);
    const [validationErrors, setValidationErrors] = React.useState<any>();

    const handleSubmit = useCallback(async (values: any) => {
        let validationErrors: any = null;

        await updateEmployee(id, {
            ...values,
            birth_date: values.birth_date || null,
            residence_permit: values.residence_permit || null,
            residence_permit_expiration_date: values.residence_permit_expiration_date || null,
            identity_document_type: values.identity_document_type || null,
            identity_document_expiration_date: values.identity_document_expiration_date || null,
            marital_status: values.marital_status || null,
            other_employment_type: values.other_employment_type || null,
            application_date: values.application_date || null,
            probation_start_date: values.probation_start_date || null,
            probation_day_count: values.probation_day_count || null,
            start_date: values.start_date || null,
            end_date: values.end_date || null,
            contract_form: values.contract_form || null,
            contract_start_date: values.contract_start_date || null,
            contract_end_date: values.contract_end_date || null,
            photo_url: values.photo_url && values.photo_url[0]?.response?.photo_url,
            primary_branch_id: values.primary_branch_id || null,
            primary_role_id: values.primary_role_id || null,
            primary_work_area_id: values.primary_work_area_id || null,
            health_blood_type: values.health_blood_type || null,
        }).then(() => {
            validationErrors = null;
            message.success(t('form.submit.result.success'));

            navigate('/files/personnel');
        }).catch((e) => {
            message.error(t('form.submit.result.error'), 10);

            if (e.response && e.response?.status === 422) {
                validationErrors = e.response?.data?.errors;

                window.scrollTo(0, 0);
            }

        }).finally(() => {
            setValidationErrors(validationErrors);
        });
    }, [id, navigate, t]);

    const fullName = data ? `${data.data.first_name} ${data.data.last_name}` : undefined;
    const pageContent = useMemo(() =>
            data ? (
                <PersonnelForm
                    personnel={data!.data}
                    handleSubmit={handleSubmit}
                />
            ) : undefined
        , [data, handleSubmit]);

    return (
        <>
            {validationErrors && (
                <Alert
                    message={ct('validation-error')}
                    showIcon
                    description={
                        <div>
                            {
                                Object.values(validationErrors).map((validationError) => {
                                    return (
                                        <ul key={validationError as string}>
                                            <li>{validationError as string}</li>
                                        </ul>
                                    );
                                })
                            }
                        </div>
                    }
                    type="error"
                    banner={true}
                />
            )}
            <PageContainer
                title={`Edit ${fullName || 'Personnel'}`}
                loading={loading}
                fixedHeader={true}
                affixProps={{
                    offsetTop: 56,
                }}
                header={{
                    footer: (
                        <Anchor
                            targetOffset={56 + 160 + 66}
                            direction="horizontal"
                            items={tabList}
                        />
                    ),
                }}
                extra={[
                    <Button
                        key="btnDownloadPdf"
                        type="primary"
                        onClick={() => navigate(`/files/personnel/${id}`)}
                    >
                        <EyeOutlined/> {ct('details')}
                    </Button>,
                ]}
            >
                {pageContent}
            </PageContainer>
        </>
    );
};
export default EditPersonnelForm;
