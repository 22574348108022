import { IdentityTranslations } from '../../types';

const identity: IdentityTranslations = {
    account: 'Hesap',
    logout: 'Çıkış yap',
    email: {
        placeholder: 'Lütfen e-posta adresinizi girin',
        required: 'E-posta adresi gereklidir',
    },
    password: {
        placeholder: 'Lütfen şifrenizi girin',
        required: 'Şifre gereklidir',
    },
    remember: 'Beni hatırla',
    forgot: 'Şifremi unuttum',
    idle: {
        prompt: {
            title: 'Oturumunuz kapatılacak',
            description: '{{idleMinutes}} dakikadır etkin değilsiniz. Oturumunuz sonlandırılacak.',
        },
    },
    'forgot-password': {
        description: 'Şifrenizi sıfırlamak için e-posta adresinizi girin.',
        button: {
            submit: 'Gönder',
        },
        failure: {
            content: 'Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.',
        },
        success: {
            button: {
                ok: 'Tamam',
            },
            title: 'E-posta gönderildi',
            content: 'Şifrenizi sıfırlamak için bir bağlantı e-posta adresinize gönderildi.',
        },
    },
    'reset-password': {
        title: 'Şifre Sıfırlama',
        password: {
            placeholder: 'Lütfen yeni şifrenizi girin',
            required: 'Yeni şifre gereklidir',
        },
        'password-confirmation': {
            placeholder: 'Lütfen yeni şifrenizi tekrar girin',
            required: 'Şifre tekrarı gereklidir',
            mismatch: 'Şifreler eşleşmiyor',
        },
        'identity-verification-notice': 'Kimliğiniz doğrulandı. Şimdi yeni şifrenizi belirleyebilirsiniz.',
        submit: 'Şifreyi sıfırla',
        success: 'Şifreniz başarıyla sıfırlandı.',
    },
    rbac: {
        errors: {
            'access-denied': {
                title: 'Erişim reddedildi',
                subtitle: 'Üzgünüz, bu sayfaya erişim izniniz yok.',
                hint: 'Bunun bir hata olduğunu düşünüyorsanız, lütfen yöneticinize size aşağıdaki izinlerden birini vermesi için başvurun:',
                actions: {
                    'go-home': 'Anasayfaya dön',
                },
            },
        },
    },
};

export default identity;
