import React, { MutableRefObject, useMemo, useRef } from 'react';
import { Alert, DatePicker, Divider, Form, FormInstance, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import {
    getListBranches,
    postDuplicateBranchWeeklyTimePlan,
} from '../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

type DuplicateWeekFormProps = {
    form: React.MutableRefObject<any>;
    initialBranchId: number | null;
    initialWeek: dayjs.Dayjs | null;
};

const { success } = Modal;

const DuplicateWeekForm: React.FC<DuplicateWeekFormProps> = ({
    form: formRef,
    initialBranchId,
    initialWeek,
}) => {
    const [form] = Form.useForm();
    formRef.current = form;

    const {
        data: branchList,
        loading: loadingBranches,
    } = useRequest(getListBranches);
    const { t } = useTranslation('personnel-planning', {
        keyPrefix: 'duplicate-week-modal.form',
    });
    const { t: ct } = useTranslation('common');

    const branchOptions = useMemo(() => branchList?.data.map((branch) => ({
        label: branch.name,
        value: branch.id,
    })), [branchList]);

    if (!formRef.current) {
        return <>loading...</>
    }

    return (
        <Form
            form={formRef.current}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{
                from_branch_id: initialBranchId,
                from_week: initialWeek,
                to_branch_id: initialBranchId,
                to_week: initialWeek?.add(1, 'week'),
            }}
        >
            <Divider orientation="left">
                {t('from')}
            </Divider>
            <Form.Item
                key={uuidv4()}
                label={t('branch')}
                name="from_branch_id"
                style={{ marginBottom: 10 }}
                rules={[
                    {
                        required: true,
                        message: ct('validation.required'),
                    },
                ]}
            >
                <Select
                    loading={loadingBranches}
                    placeholder="Branch"
                    options={branchOptions}
                />
            </Form.Item>
            <Form.Item
                label={t('week')}
                name="from_week"
                rules={[
                    {
                        required: true,
                        message: ct('validation.required'),
                    },
                ]}
            >
                <DatePicker picker="week" style={{ width: '100%' }}/>
            </Form.Item>
            <Divider orientation="left">
                {t('to')}
            </Divider>
            <Form.Item
                label={t('branch')}
                name="to_branch_id"
                rules={[
                    {
                        required: true,
                        message: ct('validation.required'),
                    },
                ]}
            >
                <Select
                    loading={loadingBranches}
                    placeholder="Branch"
                    options={branchOptions}
                />
            </Form.Item>
            <Form.Item
                label={t('week')}
                name="to_week"
                style={{ marginBottom: 10 }}
                rules={[
                    {
                        required: true,
                        message: ct('validation.required'),
                    },
                ]}
            >
                <DatePicker picker="week" style={{ width: '100%' }}/>
            </Form.Item>
        </Form>
    );
};

type DuplicateWeekModalProps = {
    initialBranchId: number | null;
    initialWeek: dayjs.Dayjs | null;
    open: boolean;
    onClose: () => void;
};

const DuplicateWeekModal: React.FC<DuplicateWeekModalProps> = ({
    initialBranchId,
    initialWeek,
    open,
    onClose,
}) => {
    const form: MutableRefObject<null|FormInstance> = useRef(null);
    const [requestInProgress, setRequestInProgress] = React.useState<boolean>(false);
    const [errorMessages, setErrorMessages] = React.useState<{[key:string]: Array<string>}>({});
    const { t } = useTranslation('personnel-planning', {
        keyPrefix: 'duplicate-week-modal',
    });
    const { t: ct } = useTranslation('common');

    return (
        <Modal
            title={t('title')}
            open={open}
            onOk={() => {
                setErrorMessages({});
                form.current?.validateFields().then((values) => {
                    setRequestInProgress(true);

                    postDuplicateBranchWeeklyTimePlan(
                        values.from_branch_id,
                        values.from_week,
                        {
                            type: 'A',
                            to_branch_id: values.to_branch_id,
                            to_week: values.to_week.format('YYYY-WW'),
                        }
                    ).then((response) => {
                        success({
                            title: t('form.result.success'),
                            onOk: onClose,
                        });
                    }).catch((e) => {
                        if (e.response?.status === 422) {
                            setErrorMessages(e.response.data.errors);
                            message.error(t('form.result.error'));
                        }
                    }).finally(() => {
                        setRequestInProgress(false);
                    })
                }).catch((error) => {
                    message.error(ct('validation.empty-fields'));
                });
            }}
            onCancel={onClose}
            confirmLoading={requestInProgress}
            destroyOnClose
        >
            { Object.keys(errorMessages).length > 0 && (
                <Alert
                    message={ct('validation.error-title')}
                    description={
                        <ul style={{ padding: 0 }}>
                            { Object.keys(errorMessages).map((key) => (
                                <li key={key}>{errorMessages[key].join(', ')}</li>
                            ))}
                        </ul>
                    }
                    type="error"
                    showIcon
                />
            )}
            <DuplicateWeekForm
                form={form}
                initialBranchId={initialBranchId}
                initialWeek={initialWeek}
            />
        </Modal>
    );
};

export default DuplicateWeekModal;
