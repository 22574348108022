import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { ListedPermission } from '../types';
import { LoginResponse } from './network/api/v1/types';

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function usePermissions(): string[] {
    const user: LoginResponse['user']|null = useAuthUser();

    const permissions = useMemo(() => user?.employee
            .permissions
            .reduce((acc: string[], permission: ListedPermission) => {
                acc.push(permission.name);

                return acc;
            }, []) || []
        , [user]);

    return permissions;
}
