import { Modal, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { getEmployeeUser } from '../../../../../utils/network/api/v1';
import { EmployeeUserResource } from '../../../../../types';
import ExistingUserView from './ExistingUserView/ExistingUserView';
import UserPermissions from './UserPermissions';
import NewUserView from './NewUserView';
import { Rbac } from '../../../../../components/Auth';
import { UserModalProps } from './types';
import styles from './styles';

const UserModal: React.FC<UserModalProps> = ({
    personnelId,
    isOpen,
    onCancel,
}) => {
    const [data, setData] = React.useState<EmployeeUserResource>();
    const [loading, setLoading] = React.useState(false);
    const [childBusy, setChildBusy] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        getEmployeeUser(personnelId)
            .then((res) => {
                setData(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [personnelId]);

    const busy = loading || childBusy;

    const personnel = data?.data;
    const user = personnel?.user;
    const allPermissions = data?.meta.permissions;
    const onChildBusy = (busy: boolean) => setChildBusy(busy);

    const content = (
        <div style={styles.container}>
            <div style={{ width: user ? 600 : '100%' }}>
                <div style={styles.centered}>
                    {loading ? (
                        <div style={styles.loadingContainer}>
                            <Skeleton avatar paragraph={{ rows: 4 }} />
                        </div>
                    ) : (
                        user
                            ? (
                                <ExistingUserView
                                    data={data!}
                                    setData={setData}
                                    busy={busy}
                                    setBusy={onChildBusy}
                                />
                            ) : (
                                <NewUserView
                                    data={data!}
                                    setData={setData}
                                    busy={busy}
                                    onBusy={onChildBusy}
                                />
                            )
                    )}
                </div>
            </div>
            {user && (
                <UserPermissions
                    data={data!}
                    setData={setData}
                    busy={busy}
                    definitions={allPermissions!}
                />
            )}
        </div>
    );

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            maskClosable={false}
            confirmLoading={busy}
            cancelButtonProps={styles.cancelButtonProps}
            footer={null}
            width={1000}
        >
            {(
                <Rbac permission="user-management:access">{content}</Rbac>
            )}
        </Modal>
    );
};

export default UserModal;
