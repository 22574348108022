import React from 'react';
import { Input } from '@formily/antd-v5';
import { Alert, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { postStoreEmployeeUser } from '../../../../../../utils/network/api/v1';
import { EmployeeUserResource } from '../../../../../../types';
import { useTranslation } from 'react-i18next';

type NewUserViewProps = {
    data: EmployeeUserResource;
    setData: React.Dispatch<React.SetStateAction<EmployeeUserResource | undefined>>,
    busy: boolean,
    onBusy: (busy: boolean) => void;
}

export default function NewUserView ({
    data,
    setData,
    busy,
    onBusy,
}: NewUserViewProps) {
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'account-management.new-user',
    });

    const employee = data?.data;

    const createUser = async () => {
        onBusy(true);

        try {
            postStoreEmployeeUser(employee.id).then((res) => {
                setData(res.data);
            });
        } finally {
            onBusy(false);
        }
    };

    return (
        <div style={{ marginTop: 50, width: 550, margin: '0 auto' }}>
            <p dangerouslySetInnerHTML={{ __html: t('description') }} />
            <Input disabled={true} value={employee?.email_address} style={{ marginBottom: 10 }}/>
            <Alert
                style={{ marginBottom: 10 }}
                message={<span dangerouslySetInnerHTML={{ __html: t('create-user.description') }} />}
                description={(
                    <ul style={{ margin: 0, paddingLeft: 15 }}>
                        <li>{ t('create-user.note-1') }</li>
                        <li>{ t('create-user.note-2') }</li>
                        <li>{ t('create-user.note-3') }</li>
                    </ul>
                )}
                type="info"
                showIcon
            />
            <Button
                type="primary"
                icon={<UserAddOutlined/>}
                onClick={createUser}
                disabled={busy}
            >
                { t('create-user.action.text') }
            </Button>
        </div>
    )
};
