import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import "/node_modules/flag-icons/css/flag-icons.min.css";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://480d327e7a6e4c8499d2d700f74e610a@o1217468.ingest.sentry.io/4504916997570560",

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // Browser tracing sampling rate.
        tracesSampleRate: 1.0,

        integrations: [new Sentry.Replay(), new BrowserTracing()],
    });
}
