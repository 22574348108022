import { PersonnelFilesTranslations } from '../../types';

const personnelFiles: PersonnelFilesTranslations = {
    'index': {
        'full-name': 'Ad Soyad',
        'company': 'Şirket',
        'role': 'Rol',
        'branch': 'Şube',
        'actions': 'İşlemler',
        'create-personnel-file': 'Personel Dosyası Oluştur',
        filters: {
            'state': {
                'options': {
                    'all': 'Tümü',
                    'active': 'Aktif',
                    'inactive': 'Pasif',
                },
            },
        },
    },
    'view': {
        'title': '{{full_name}} Personel Dosyası',
        'sections': {
            'personal-information': 'Personel',
            'address-information': 'Adres',
            'passport-information': 'Kimlik ve Pasaport',
            'tax-and-social-security-information': 'Vergi ve Sosyal Güvenlik',
            'payment-information': 'Ödeme',
            'work-information': 'İş',
            'contract-information': 'Sözleşme',
            'personal-health-information': 'Kişisel & Sağlık',
            'health-passport-information': 'Sağlık Pasaportu',
            'emergency-contact-information': 'Acil Durum İletişim',
        },
        'account-management': {
            'title': 'Hesap Yönetimi',
        },
    },
    'create-personnel-file': {
        'title': 'Personel Dosyası Oluştur',
    },
    'edit-personnel-file': {
        'title': 'Personel Dosyası Düzenle',
    },
    'form': {
        'submit': {
            'result': {
                'success': 'Personel dosyası başarıyla kaydedildi.',
                'error': 'Personel dosyası kaydedilirken bir hata oluştu. Lütfen tekrar deneyiniz.',
            },
        },
        'personnel-information': {
            'title': 'Personel Bilgileri',
            'first-name': 'Ad',
            'last-name': 'Soyad',
            'full-name': 'Ad Soyad',
            'birth-name': 'Doğum Adı',
            'email-address': 'E-posta',
            'phone-number': 'Telefon Numarası',
        },
        'address-information': {
            'title': 'Adres Bilgileri',
            'address-street': 'Sokak',
            'address-number': 'Numara',
            'address-city': 'Şehir',
            'address-postcode': 'Posta Kodu',
        },
        'passport-information': {
            'title': 'Kimlik ve Pasaport Bilgileri',
            'birth-city': 'Doğum Yeri',
            'birth-date': 'Doğum Tarihi',
            'birth-country': 'Doğduğu Ülke',
            'identity-document': {
                'title': 'Kimlik/Pasaport',
                'types': {
                    'national-id': 'Kimlik Kartı',
                    'passport': 'Pasaport',
                },
            },
            'identity-document-number': 'Kimlik/Pasaport Numarası',
            'identity-document-expiration-date': 'Kimlik/Pasaport Geçerlilik Tarihi',
            'nationality': 'Uyruk',
            'ethnicity': 'Etnik Köken',
            'residence-permit': {
                'title': 'İkamet İzni',
                'types': {
                    'visa': 'Vize',
                    'tourist-visa': 'Turist Vizesi',
                    'work-visa': 'Çalışma Vizesi',
                    'student-visa': 'Öğrenci Vizesi',
                    'family-reunion-visa': 'Aile Birleşimi Vizesi',
                    'asylum': 'Sığınmacı',
                    'refugee': 'Mülteci',
                    'eu': 'Avrupa Birliği Vatandaşı',
                    'eu-blue-card': 'Mavi Kart',
                    'permanent-residence': 'Süresiz Oturum',
                    'citizenship': 'Vatandaşlık',
                },
            },
            'residence-permit-expiration-date': 'İkamet İzni Geçerlilik Tarihi',
        },
        'tax-and-social-security-information': {
            'title': 'Vergi ve Sosyal Güvenlik Bilgileri',
            'tax-id': 'Vergi Kimlik Numarası',
            'health-insurance-company-name': 'Sağlık Sigortası Şirketi',
            'health-insurance-number': 'Sağlık Sigortası Numarası',
            'social-security-number': 'Sosyal Güvenlik Numarası',
            'marital-status': {
                'title': 'Medeni Durum',
                'options': {
                    'married': 'Evli',
                    'single': 'Bekar',
                    'divorced': 'Boşanmış',
                    'widowed': 'Dul',
                },
            },
            'has-children': {
                'title': 'Çocuğu Var mı?',
                'options': {
                    'yes': 'Evet',
                    'no': 'Hayır',
                },
            },
            'employee-children': {
                title: 'Çocuklar',
                name: {
                    placeholder: 'Ad',
                },
                birth_date: {
                    placeholder: 'Doğum Tarihi',
                },
            },
            'children-count': 'Çocuk Sayısı',
            'child-allowance': 'Çocuk Yardımı',
            'other-employment': {
                'title': 'Başka Bir İşte Çalışıyor mu?',
                'options': {
                    'yes': 'Evet',
                    'no': 'Hayır',
                },
            },
            'other-employment-type': {
                'title': 'Diğer İşveren Türü',
                'options': {
                    'mini-job': 'Mini Job',
                    'subject-to-social-insurance': 'Sosyal Sigortaya Tabi',
                },
            },
            'other-employment-employer': 'Diğer İşveren',
        },
        'payment-information': {
            'title': 'Ödeme Bilgileri',
            'bank-name': 'Banka Adı',
            'bank-iban': 'IBAN Numarası',
            'bank-bic': 'BIC Numarası',
            'bank-account-holder': 'Hesap Sahibi',
            'different-bank-account-holder': 'Farklı Hesap Sahibi',
        },
        'work-information': {
            'title': 'İş Bilgileri',
            'application-date': 'Başvuru Tarihi',
            'application-role-id': 'Başvurulan Pozisyon',
            'probation-start-date': 'Deneme Süresi Başlangıç Tarihi',
            'probation-branch-id': 'Denendiği Şubesi',
            'probation-days': {
                'title': 'Deneme Günleri',
                'options': {
                    '1': '1 Gün',
                    '2': '2 Gün',
                    '3': '3 Gün',
                },
            },
            'start-date': 'Başlangıç Tarihi',
            'end-date': 'Bitiş Tarihi',
            'personnel-number': 'Personel Numarası',
            'has-driving-license': {
                'title': 'Sürücü Belgesi Var mı?',
                'options': {
                    'yes': 'Evet',
                    'no': 'Hayır',
                },
            },
            'driving-license-number': 'Sürücü Belgesi Numarası',
            'tasks': 'Görevler',
            'education-school': 'Okul Eğitimi',
            'work-experience': 'İş Deneyimi',
            'skills': 'Yetenekler',
        },
        'contract-information': {
            'title': 'Sözleşme Bilgileri',
            'history': 'Geçmiş',
            'snapshot-date': 'Anlık Görüntü Tarih',
            'contract-form': {
                'title': 'Sözleşme Türü',
                'options': {
                    'full_time': 'Tam Zamanlı',
                    'part_time': 'Yarı Zamanlı',
                    'mini_job': 'Mini Job',
                    'working_student': 'Öğrenci',
                },
            },
            'contract-start-date': 'Sözleşme Başlangıç Tarihi',
            'contract-end-date': 'Sözleşme Bitiş Tarihi',
            'primary-branch-id': 'Şube',
            'primary-role-id': 'Rol',
            'primary-work-area-id': 'Çalışma Alanı',
            'salary-gross': 'Brüt Maaş',
            'salary-net': 'Net Maaş',
            'tax-class': {
                'title': 'Vergi Sınıfı',
                'options': {
                    '1': 'Sınıf 1',
                    '2': 'Sınıf 2',
                    '3': 'Sınıf 3',
                    '4': 'Sınıf 4',
                    '5': 'Sınıf 5',
                    'G': 'Sınıf G',
                },
            },
            'social-expense-employer': 'İşveren Sosyal Güvenlik Payı',
            'social-expense-employee': 'İşçi Sosyal Güvenlik Payı',
            'work-hours-per-month': 'Aylık Çalışma Saati',
            'work-hours-per-month-error': 'Aylık çalışma süresi {{min}} ile {{max}} saat arasında olmalıdır.',
            'hourly-rate-gross': 'Brüt Saatlik Ücret',
            'hourly-rate-net': 'Net Saatlik Ücret',
            'work-hours-per-day-a': 'Günlük Çalışma Saati',
            'work-hours-per-day-min-a': 'Min Günlük Çalışma Saati',
            'work-hours-per-day-max-a': 'Max Günlük Çalışma Saati',
            'work-days-per-week-a': 'Haftalık Çalışma Saati',
            'work-days-per-month-a': 'Aylık Çalışma Günü',
            'salary-fixed': 'Sabit Maaş',
            'work-hours-per-day-b': 'Günlük Çalışma Saati',
            'work-hours-per-day-min-b': 'Min Günlük Çalışma Saati',
            'work-hours-per-day-max-b': 'Max Günlük Çalışma Saati',
            'work-days-per-week-b': 'Haftalık Çalışma Saati',
            'work-days-per-month-b': 'Aylık Çalışma Günü',
            'meal-cost-per-day-a': 'Günlük Yemek Maliyeti',
            'meal-cost-per-day-b': 'Günlük Yemek Maliyeti',
            'bonus-premium': {
                'title': 'İkramiye ve Ödül',
                'resource': {
                    'title': 'Kaynak',
                    'options': {
                        'holding_total': 'Holding Toplamı',
                        'company_total': 'Şirket Toplamı',
                        'branch_total': 'Şube Toplamı',
                        'personal_total': 'Personel Toplamı',
                        'kitchen_total': 'Mutfak Toplamı',
                        'bar_total': 'Bar Toplamı',
                    },
                },
                'gross-net': {
                    'title': 'Brüt/Net',
                    'options': {
                        'gross': 'Brüt',
                        'net': 'Net',
                    },
                },
                'premium-rate': 'Premi Oranı',
            },
        },
        'personal-health-information': {
            'title': 'Kişisel & Sağlık Bilgileri',
            'hobbies': 'Hobiler',
            'height': 'Boy',
            'weight': 'Kilo',
            'blood-type': 'Kan Grubu',
            'diseases': 'Hastalıklar',
            'medications': 'İlaçlar',
            'addictions': 'Bağımlılıklar',
        },
        'health-passport-information': {
            'title': 'Sağlık Pasaportu Bilgileri',
            'has-health-passport': 'Sağlık Pasaportu Var mı?',
            'health-passport-issue-date': 'Sağlık Pasaportu Veriliş Tarihi',
            'health-passport-expiration-date': 'Sağlık Pasaportu Geçerlilik Tarihi',
        },
        'emergency-contact-information': {
            'title': 'Acil Durum İletişim Bilgileri',
            'name': 'Adı',
            'relationship': 'İlişki',
            'phone-number': 'Telefon Numarası',
            'email-address': 'E-posta Adresi',
            'address': 'Adres',
        },
        'sections': {
            'personal-information': 'Personel',
            'address-information': 'Adres',
            'passport-information': 'Kimlik ve Pasaport',
            'tax-and-social-security-information': 'Vergi ve Sosyal Güvenlik',
            'payment-information': 'Ödeme',
            'work-information': 'İş',
            'contract-information': 'Sözleşme',
            'personal-health-information': 'Kişisel & Sağlık',
            'health-passport-information': 'Sağlık Pasaportu',
            'emergency-contact-information': 'Acil Durum İletişim',
        },
    },
    'account-management': {
        'new-user': {
            'description': 'Bu personel henüz giriş yapabilecek bir kullanıcıya sahip değil.',
            'create-user': {
                'description': 'Yeni bir kullanıcı hesabı oluşturmak için <b>Kullanıcı Oluştur</b> butonuna tıklayın.',
                'note-1': 'Kullanıcı adı, personelin e-posta adresi olacaktır.',
                'note-2': 'Bu e-posta adresine bir şifre gönderilecektir.',
                'note-3': 'Hesap oluşturduktan sonra erişim haklarını düzenleyebilirsiniz.',
                'action': {
                    'text': 'Kullanıcı Oluştur',
                },
            },
        },
        'existing-user': {
            'description': 'Bu personel zaten giriş yapabilecek bir kullanıcıya sahip.',
            'send-password': {
                'title': 'Şifre Gönder',
                'action': {
                    'text': 'Şifre Gönder',
                },
                'success': {
                    'description': 'Şifre <b>{{password}}</b> olarak ayarlandı ve personelin e-posta adresine gönderildi.',
                    'note': '<b>Not:</b> Bu şifre, personel tarafından değiştirilebilir.',
                },
                'error': 'Şifre gönderilemedi. Lütfen daha sonra tekrar deneyin.',
            },
            'delete-user': {
                'action': {
                    'text': 'Kullanıcıyı Sil',
                },
                'error': 'Kullanıcı silinemedi. Lütfen daha sonra tekrar deneyin.',
            },
            'permissions': {
                'title': 'İzinler',
            },
        },
    },
};

export default personnelFiles;
