import { IdentityTranslations } from '../../types';

const identity: IdentityTranslations = {
    account: 'Konto',
    logout: 'Ausloggen',
    email: {
        placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        required: 'E-Mail-Adresse ist erforderlich',
    },
    password: {
        placeholder: 'Bitte geben Sie Ihr Passwort ein',
        required: 'Passwort ist erforderlich',
    },
    remember: 'Angemeldet bleiben',
    idle: {
        prompt: {
            title: 'Sie werden abgemeldet',
            description: 'Sie sind seit {{idleMinutes}} Minuten inaktiv. Sie werden bald abgemeldet.',
        },
    },
    forgot: 'Passwort vergessen',
    'forgot-password': {
        'description': 'Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts zu erhalten.',
        button: {
            submit: 'Absenden',
        },
        failure: {
            content: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.',
        },
        success: {
            button: {
                ok: 'OK',
            },
            title: 'E-Mail gesendet',
            content: 'Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet.',
        },
    },
    'reset-password': {
        'title': 'Passwort zurücksetzen',
        'identity-verification-notice': 'Ihre Identität wurde überprüft. Sie können jetzt Ihr neues Passwort festlegen.',
        password: {
            placeholder: 'Bitte geben Sie Ihr neues Passwort ein',
            required: 'Neues Passwort ist erforderlich',
        },
        'password-confirmation': {
            placeholder: 'Bitte bestätigen Sie Ihr neues Passwort',
            required: 'Passwortbestätigung ist erforderlich',
            'mismatch': 'Passwörter stimmen nicht überein',
        },
        'submit': 'Passwort zurücksetzen',
        'success': 'Passwort erfolgreich zurückgesetzt',
    },
    rbac: {
        errors: {
            'access-denied': {
                title: 'Zugriff verweigert',
                subtitle: 'Entschuldigung, Sie sind nicht berechtigt, auf diese Seite zuzugreifen.',
                hint: 'Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren Administrator, um Ihnen eine der folgenden Berechtigungen zu erteilen:',
                actions: {
                    'go-home': 'Zurück zur Startseite',
                },
            },
        },
    },
};

export default identity;
