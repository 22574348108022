import React from 'react';
import { Avatar, Card } from 'antd';
import TitleDescription from './TitleDescription';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

type TitleCardProps = {
    personnel: any;
    mode: 'default' | 'compact';
}

const TitleCard: React.FC<TitleCardProps> = ({ personnel, mode }) => {
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'view',
    });

    return (
        <Card
            bordered={false}
            style={{
                backgroundColor: 'unset',
            }}
            bodyStyle={mode === 'compact' ? {
                padding: 3,
            } : undefined}
        >
            <Meta
                avatar={
                    <Avatar
                        size={(mode === 'compact') ? 32 : {
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100,
                        }}
                        src={personnel?.photo_url || undefined}
                        style={{ marginRight: 16 }}
                        icon={<UserOutlined />}
                    />
                }
                title={t('title', { full_name: personnel?.full_name })}
                description={personnel && (
                    <TitleDescription personnel={personnel} display={mode === 'default' }/>
                )}
            />
        </Card>
    )
}

export default TitleCard;
