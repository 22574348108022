import { PersonnelPlanningTranslations } from '../../types';

const personnelPlanning: PersonnelPlanningTranslations = {
    'role': 'Rol',
    'time': 'Zaman',
    'starter-hint': 'Haftayı planlamaya başlamak için lütfen bir şube seçin.',
    'edit': 'Düzenle',
    'duplicate': 'Çoğalt',
    'one-line-alert-message': 'Aynı anda yalnızca bir satır ekleyebilirsiniz.',
    'delete-popconfirm-message': 'Bu satırı silmek istediğinizden emin misiniz?',
    'personnel-select': {
        'preview-hint': 'Çalışanı önizlemek için lütfen ⓘ simgesine tıklayın.',
        'option-groups': {
            'search-results': {
                'title': 'Arama sonuçları',
            },
            'previously-selected-results': {
                'title': 'Daha önce seçilen sonuçlar',
            },
        },
        'start-typing-to-search': 'Aramaya başlamak için yazmaya başlayın...',
    },
    'preview': {
        'title': 'Personel Planı Önizlemesi',
    },
    'duplicate-week-modal': {
        'title': 'Haftalık Planı Çoğalt',
        'form': {
            'from': 'Kaynak Hafta',
            'to': 'Yeni Hafta',
            'branch': 'Şube',
            'week': 'Hafta',
            result: {
                success: 'Haftalık plan başarıyla çoğaltıldı.',
                error: 'Haftalık plan çoğaltılamadı.',
            },
        },
    },
};

export default personnelPlanning;
