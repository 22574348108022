import React from 'react';
import { Input } from '@formily/antd-v5';
import { Alert, Button, message, Typography } from 'antd';
import { SendOutlined, UserDeleteOutlined } from '@ant-design/icons';
import {
    deleteEmployeeUser,
    postEmployeeUserResetPassword,
} from '../../../../../../utils/network/api/v1';
import { ExistingUserViewProps } from './types';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const ExistingUserView: React.FC<ExistingUserViewProps> = ({
    data,
    setData,
    busy,
    setBusy,
}) => {
    const { t } = useTranslation('personnel-files', {
        keyPrefix: 'account-management.existing-user',
    });
    const personnel = data.data;
    const user = personnel.user;

    const [
        sendingPassword,
        setSendingPassword,
    ] = React.useState(false);

    const [
        deletingUser,
        setDeletingUser,
    ] = React.useState(false);

    const sendNewPassword = async () => {
        setSendingPassword(true);
        try {
            await postEmployeeUserResetPassword(personnel.id).then((res) => {
                setData(res.data);
            });
        } catch (e) {
            message.error(t('send-password.error'));
        } finally {
            setSendingPassword(false);
        }
    };

    const deleteUser = async () => {
        setBusy(true);
        setDeletingUser(true);

        try {
            await deleteEmployeeUser(personnel.id).then((res) => {
                setData(res.data);
            });
        } catch (e) {
            message.error(t('delete-user.error'));
        } finally {
            setBusy(false);
            setDeletingUser(false);
        }
    };

    return (
        <div style={{ marginTop: 60, width: 375, margin: '0 auto' }}>
            <p>{t('description')}</p>
            <Input disabled={true} value={user.email} style={{ marginBottom: 10 }}/>
            {user.password && (
                <Alert
                    style={{ marginBottom: 10 }}
                    message={t('send-password.title')}
                    description={
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{ __html: t('send-password.success.description', { password: user.password }) }} />
                            <br /><br />
                            <span dangerouslySetInnerHTML={{ __html: t('send-password.success.note')}} />
                        </Paragraph>
                    }
                    type="success"
                    showIcon
                />
            )}
            <Button
                type="primary"
                icon={<SendOutlined/>}
                onClick={sendNewPassword}
                disabled={busy}
                loading={sendingPassword}
            >
                { t('send-password.action.text') }
            </Button>
            <Button
                type="primary"
                icon={<UserDeleteOutlined/>}
                style={{ float: 'right' }}
                onClick={deleteUser}
                disabled={busy}
                loading={deletingUser}
                danger
            >
                { t('delete-user.action.text') }
            </Button>
        </div>
    );
};

export default ExistingUserView;
