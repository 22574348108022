import { CommonTranslations } from '../types';

const common: CommonTranslations = {
    id: 'ID',
    loading: 'Laden...',
    index: 'Index',
    create: 'Erstellen',
    add: 'Element hinzufügen',
    details: 'Einzelheiten',
    edit: 'Bearbeiten',
    ok: 'Ok',
    'validation-error': 'Validierungsfehler',
    reminder: {
        'main-form-submission': 'Bitte denken Sie daran, das Hauptformular zu speichern',
    },
    submit: 'Speichern',
    delete: 'Löschen',
    yes: 'Ja',
    no: 'Nein',
    since: 'Seit: {{date}}',
    actions: 'Aktionen',
    print: 'Drucken',
    day: {
        title: 'Tag',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
        short: {
            monday: 'Mo',
            tuesday: 'Di',
            wednesday: 'Mi',
            thursday: 'Do',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'So',
        },
    },
    download: {
        pdf: 'PDF herunterladen',
        'pdf-named': '{{name}} PDF herunterladen',
        'in-progress': 'PDF wird erstellt...',
    },
    network: {
        error: {
            'no-connection': 'Keine Netzwerkverbindung',
            '403': 'Sie haben keine Berechtigung, diese Aktion auszuführen',
            '404': 'Server konnte die Ressource nicht finden',
            '500': 'Server konnte die Anfrage nicht verarbeiten',
            '503': 'Server wird gewartet. Bitte versuchen Sie es später noch einmal',
        },
    },
    message: {
        success: 'Erfolgreich',
        error: 'Fehler',
    },
    reset: {
        question: 'Zurücksetzen?',
        description: 'Möchten Sie wirklich alle Änderungen verwerfen?',
    },
    validation: {
        'error-title': 'Validierungsfehler',
        required: 'Dieses Feld ist erforderlich',
        'empty-fields': 'Bitte füllen Sie alle Felder aus.',
    },
    input: {
        text: {
            placeholder: 'Bitte eingeben...',
        },
        number: {
            placeholder: 'Bitte eingeben...',
        },
        select: {
            none: 'Keine',
            placeholder: 'Bitte auswählen...',
        },
        date: {
            format: 'DD.MM.YYYY',
            placeholder: 'Bitte auswählen...',
        },
        tags: {
            placeholder: 'Bitte schreiben und Enter drücken...',
        },
    },
    menu: {
        activities: {
            title: 'Aktivität',
            dashboard: {
                title: 'Dashboard',
            },
            'daily-report': {
                title: 'Tagesbericht',
            },
            documentation: {
                title: 'Dokumentation',
            }
        },
        planning: {
            title: 'Planung',
            'personnel-planning': {
                title: 'Personalplanung',
            },
            'supply-planning': {
                title: 'Lieferplanung',
            },
            'service-planning': {
                title: 'Serviceplanung',
            },
        },
        files: {
            title: 'Akten',
            'personnel-files': {
                title: 'Personalakte',
            },
            'supply-files': {
                title: 'Lieferakte',
            },
            'service-files': {
                title: 'Serviceakte',
            },
        },
        costs: {
            title: 'Kosten',
            'personnel-costs': {
                title: 'Personalkosten',
            },
            'supply-costs': {
                title: 'Lieferkosten',
            },
            'service-costs': {
                title: 'Servicekosten',
            },
        },
        finance: {
            title: 'Finanzen',
            'general-finances': {
                title: 'Allgemeine Finanzen',
            },
            'branch-finances': {
                title: 'Filialfinanzen',
            },
            'sales-finances': {
                title: 'Umsatzfinanzen',
            },
        },
        warehouse: {
            title: 'Lager',
        },
        'start-up': {
            title: 'Start-up',
        },
    },
};

export default common;
