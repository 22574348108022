import { Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonnelContractHistory from './PersonnelContractHistory';

type contractHistoryDrawerProps = {
    id: number,
    employeeId: number,
    open: boolean,
    onClose: () => void,
};

const ContractHistoryDrawer = ({ id, employeeId, open, onClose }: contractHistoryDrawerProps) => {
    const { t } = useTranslation('personnel-files');

    return (
        <Drawer
            title={t('form.contract-information.history')}
            open={open}
            onClose={onClose}
            placement="bottom"
            mask={false}
            destroyOnClose
        >
            <PersonnelContractHistory employeeId={employeeId} contractId={id}/>
        </Drawer>
    );
};

export default ContractHistoryDrawer;
