import React, { useState } from 'react';
import Routes from './routes';
import i18n, { defaultLanguage, antdLocales } from './i18n';
import { ConfigProvider } from 'antd';
import { ProConfigProvider } from '@ant-design/pro-components';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

const App: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18next.language);
    i18next.on('languageChanged', (lng) => {
        setCurrentLanguage(lng);
    });

    const locale = currentLanguage in antdLocales
        ? antdLocales[currentLanguage]
        : antdLocales[defaultLanguage];

    return (
        <I18nextProvider i18n={i18n}>
            <ConfigProvider locale={locale}>
                <ProConfigProvider>
                    <Routes/>
                </ProConfigProvider>
            </ConfigProvider>
        </I18nextProvider>
    );
};

export default App;
