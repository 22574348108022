import React from 'react';
import {
    ArrayItems,
    FormButtonGroup,
    FormDrawer,
    Submit,
} from '@formily/antd-v5';
import { Button } from 'antd';
import EditCompanyForm from '../../EditCompanyForm';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { connect, mapProps } from '@formily/react';
import { StartupCompany } from '../../../../../types';
import { useTranslation } from 'react-i18next';

type CompanyItemProps = {
    children: React.ReactNode | undefined,
    disabled: boolean,
    onBlur: (value: any) => void,
    onChange: (value: StartupCompany) => void;
    onFocus: (value: any) => void;
    readOnly: boolean;
    value: any;
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        border: 1,
        borderStyle: 'solid',
        borderColor: '#d9d9d9',
        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
        marginBottom: 10,
        padding: '3px 6px',
        color: 'rgba(0, 0, 0, 0.88)',
    },
    editButton: {
        paddingRight: 10,
        color: '#aaa',
    },
    deleteButton: {
        color: 'red',
    },
    questionMarkOutlinedIcon: {
        color: 'red',
    },
};

const CompanyItem: React.FC<CompanyItemProps> = ({ onChange }) => {
    const items = (ArrayItems as any).useArray();
    const record = (ArrayItems as any).useRecord();
    const index = (ArrayItems as any).useIndex();
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');

    return (
        <div style={styles.container}>
            <span>
                {(!record?.id) && (
                    <>
                        <QuestionCircleOutlined style={styles.questionMarkOutlinedIcon}/>
                        &nbsp;
                    </>
                )}
                {record.name}
            </span>
            <div>
                <EditOutlined
                    style={styles.editButton}
                    onClick={() => {
                        const drawer = FormDrawer(t('form.company.form.title'), () => {
                            return (
                                <EditCompanyForm
                                    companyId={record.id}
                                    buttons={(onSubmit: (data: StartupCompany) => Promise<StartupCompany>) => (
                                        <FormButtonGroup align="right">
                                            {(!record.id) && (
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        items.field?.remove?.(index);
                                                        items.field?.onRemove?.(index);

                                                        drawer.close();
                                                    }}
                                                    danger
                                                >
                                                    {ct('delete')}
                                                </Button>
                                            )}
                                            <Submit onSubmit={(data) => onSubmit(data).then(onChange)}>
                                                {ct('submit')}
                                            </Submit>
                                        </FormButtonGroup>
                                    )}
                                />
                            );
                        });

                        drawer.open({
                            values: {
                                ...record,
                                name: record.name,
                            },
                        });
                    }}
                />
                {(!record.id) && (<DeleteOutlined
                    style={styles.deleteButton}
                    onClick={() => {
                        items.field?.remove?.(index);
                        items.field?.onRemove?.(index);
                    }}
                />)}
            </div>
        </div>
    );
};

const ConnectedCompanyItem = connect(
    CompanyItem,
    mapProps((props, field) => {
        return {
            ...props,
            field,
        };
    }),
);

export default ConnectedCompanyItem;
