import { DashboardTranslations } from '../../types'

const dashboard: DashboardTranslations = {
    'upcoming-birthdays': {
        title: 'Yaklaşan Doğum Günleri'
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Yaklaşan Pasaport Bitiş Tarihleri',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Yaklaşan Vize Bitiş Tarihleri',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Yaklaşan Sözleşme Bitiş Tarihleri',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Yaklaşan İkamet İzni Bitiş Tarihleri',
    },
};

export default dashboard;
