import React, { useCallback } from 'react';
import {
    ArrayItems,
    FormButtonGroup,
    FormDrawer,
    Submit,
} from '@formily/antd-v5';
import { Button } from 'antd';
import BranchForm from './BranchForm';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { connect, mapProps } from '@formily/react';
import { useTranslation } from 'react-i18next';

import { CompanyItemProps } from './types';
import styles from './styles';
import { StartupBranch } from '../../../../../../types';

const BranchItem: React.FC<CompanyItemProps> = ({ onChange }) => {
    const items = (ArrayItems as any).useArray();
    const record = (ArrayItems as any).useRecord();
    const index = (ArrayItems as any).useIndex();
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');

    const onEdit = useCallback(() => {
        const drawer = FormDrawer({
            title: t('form.company.branch.title'),
            width: 550,
        }, () => (
            <BranchForm
                buttons={(onSubmit: (data: StartupBranch) => Promise<StartupBranch>) => (
                    <FormButtonGroup align="right">
                        {(!record.id) && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    items.field?.remove?.(index);
                                    items.field?.onRemove?.(index);

                                    drawer.close();
                                }}
                                danger
                            >
                                {ct('delete')}
                            </Button>
                        )}
                        <Submit
                            onSubmit={
                                (data) => onSubmit(data).then((branch) => {
                                    onChange(branch);
                                })
                            }
                        >
                            {ct('submit')}
                        </Submit>
                    </FormButtonGroup>
                )}
            />
        ));

        drawer.open({ values: { ...record, name: record.name } });
    }, [
        index,
        record,
        items,
        onChange,
        t,
        ct,
    ]);

    return (
        <div style={styles.container}>
            <div>
                {!record?.id && (<QuestionCircleOutlined style={styles.newIcon}/>)}
                {record.name}
            </div>
            <div>
                <EditOutlined style={styles.editButton} onClick={onEdit}/>
                {!record?.id && (<DeleteOutlined
                    style={styles.deleteButton}
                    onClick={() => {
                        items.field?.remove?.(index);
                        items.field?.onRemove?.(index);
                    }}
                />)}
            </div>
        </div>
    );
};

const ConnectedBranchItem = connect(
    BranchItem,
    mapProps((props, field) => {
        return {
            ...props,
            field,
        };
    }),
);

export default ConnectedBranchItem;
