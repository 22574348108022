import React, { JSX } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSchemaField } from '@formily/react';
import {
    Form,
    FormItem,
    FormLayout,
    Input,
    ArrayItems,
    Space,
    Editable,
} from '@formily/antd-v5';

import { StartupBranch } from '../../../../../../../types';
import { upsertStartupBranch } from '../../../../../../../utils/network/api/v1';

type BranchFormProps = {
    buttons: (onSubmit: (data: StartupBranch) => Promise<StartupBranch>) => JSX.Element;
}

const SchemaField = createSchemaField({
    components: {
        FormItem,
        Input,
        ArrayItems,
        Space,
        Editable,
    },
});

const BranchForm: React.FC<BranchFormProps> = ({ buttons }) => {
    const { t } = useTranslation('start-up', {
        keyPrefix: 'form.company.branch',
    });
    const { t: ct } = useTranslation('common');

    return (
        <Form>
            <FormLayout labelCol={8} wrapperCol={16}>
                <SchemaField>
                    <SchemaField.String
                        name="name"
                        title={t('name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                    />
                    <SchemaField.Array
                        name="payment_methods"
                        title={t('payment-methods.title')}
                        x-decorator="FormItem"
                        x-component="ArrayItems"
                    >
                        <SchemaField.Object>
                            <SchemaField.Void x-component="ArrayItems.Item">
                                <SchemaField.String
                                    name="name"
                                    x-validator={[
                                        {
                                            required: true,
                                            message: ct('validation.required'),
                                        },
                                    ]}
                                    x-decorator="Editable"
                                    x-component="Input"
                                />
                                <SchemaField.Void
                                    x-decorator="FormItem"
                                    x-component="ArrayItems.Remove"
                                />
                            </SchemaField.Void>
                        </SchemaField.Object>
                        <SchemaField.Void
                            x-component="ArrayItems.Addition"
                            title={t('payment-methods.add')}
                            x-component-props={{
                                defaultValue: {
                                    name: t('payment-methods.default-name'),
                                },
                            }}
                        />
                    </SchemaField.Array>
                    <SchemaField.String
                        title={t('timezone')}
                        name="timezone"
                        x-value="UTC+1"
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                            placeholder: 'UTC+1',
                            defaultValue: 'UTC+1',
                        }}
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                    />
                </SchemaField>
                {
                    buttons((data: any) => (
                        upsertStartupBranch(data).then(({  data }) => {
                            message.success(ct('message.success'));

                            return data;
                        }).catch((error) => {
                            message.error('message.error');

                            throw error;
                        })
                    ))
                }
            </FormLayout>
        </Form>
    );
};

export default BranchForm;
