import React, { useMemo } from 'react';
import { PageContainer } from '@ant-design/pro-components';
import { Alert, Anchor, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import PersonnelForm from '../components/PersonnelForm';
import { storeEmployee } from '../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';
import { generateTabList } from './sections';
import { useScroll } from 'ahooks';
import './styles.module.scss';

const NewPersonnelForm: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('personnel-files');
    const { t: ct } = useTranslation('common');
    const tabList = generateTabList(t);
    const [validationErrors, setValidationErrors] = React.useState<any>();
    const scroll = useScroll(document);
    const titleMode = scroll?.top ? 'relative' : 'fixed';

    const pageContent = useMemo(() => {
        const handleSubmit = async (values: any) => {
            let validationErrors: any = [];

            await storeEmployee({
                ...values,
                photo_url: values.photo_url && values.photo_url[0]?.response?.photo_url,
            }).then(() => {
                message.success(t('form.submit.result.success'));

                navigate('/files/personnel');
            }).catch((e) => {
                message.error(t('form.submit.result.error'), 10);

                if (e.response && e.response?.status === 422) {
                    validationErrors = e.response?.data?.errors;

                    window.scrollTo(0, 0);
                }

            }).finally(() => {
                setValidationErrors(validationErrors);
            });
        };

        return <PersonnelForm handleSubmit={handleSubmit}/>;
    }, [navigate, t]);

    return (
        <>
            {validationErrors && (
                <Alert
                    message={ct('validation-error')}
                    showIcon
                    description={
                        <div>
                            {
                                Object.values(validationErrors).map((validationError) => {
                                    return (
                                        <ul key={validationError as string}>
                                            <li>{validationError as string}</li>
                                        </ul>
                                    );
                                })
                            }
                        </div>
                    }
                    type="error"
                    banner={true}
                />
            )}
            <PageContainer
                title={t('create-personnel-file.title')}
                fixedHeader={true}
                affixProps={{
                    offsetTop: 56,
                }}
                header={{
                    footer: (
                        <Anchor
                            targetOffset={56 + (titleMode === 'fixed' ? 260 : 160) + 66}
                            direction="horizontal"
                            items={tabList}
                        />
                    ),
                }}
            >
                {pageContent}
            </PageContainer>
        </>
    );
};

export default NewPersonnelForm;
