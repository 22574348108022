import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EditableProTable from '@ant-design/pro-table/es/components/EditableTable';
import {
    PageContainer,
    ProColumns,
    ProFieldValueType,
} from '@ant-design/pro-components';
import {
    Button,
    Col,
    Row,
    Select,
    Card,
    DatePicker,
    Tooltip,
    Divider,
} from 'antd';
import {
    SettingOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
    getBranchWeeklyTimePlan,
    upsertBranchRoleWeeklyTimePlan,
    getListBranches,
    getListRoles,
    deleteBranchRoleWeeklyTimePlan,
} from '../../../../utils/network/api/v1';
import { convertDailyTimePlanToWeeklyTimePlan, getRecordCreatorProps } from './utils';
import {
    EmployeeWeeklyTimePlan,
    ListedEmployeeData,
} from '../../../../types';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import PersonnelSelect from './PersonnelSelect';
import DuplicateWeekModal from './DuplicateWeekModal';
import { usePermissions } from '../../../../utils/hooks';
import Toolbar from './Toolbar';

const planTypeOptions = [
    {
        label: 'A',
        value: 'A',
    },
    {
        label: 'B',
        value: 'B',
    },
];

const styles = {
    branchSelect: { width: 180 },
};

const PersonnelPlanningIndex = () => {
    const { t } = useTranslation('personnel-planning');
    const { t: ct } = useTranslation('common');

    const permissions = usePermissions();
    const canUserManageAndAssign = [
        'employee-planning:manage',
        'employee-planning:assign',
    ].every((p) => permissions.includes(p));
    const canUserManageOrAssign = [
        'employee-planning:manage',
        'employee-planning:assign',
    ].some((p) => permissions.includes(p));

    const {
        data: branchList,
        loading: loadingBranches,
    } = useRequest(getListBranches);

    const [
        week,
        setWeek,
    ] = React.useState<Dayjs | null>(dayjs());

    const [
        branchId,
        setBranchId,
    ] = React.useState<number | null>(null);

    const [
        showStarterHint,
        setShowStarterHint,
    ] = React.useState<boolean>(true);

    const [
        planType,
        setPlanType,
    ] = React.useState<'A' | 'B' | null>('A');

    const [
        dataSource,
        setDataSource,
    ] = useState<readonly EmployeeWeeklyTimePlan[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedEmployees, setSelectedEmployees] = useState<ListedEmployeeData[]>([]);

    const skipOnChange = useRef(false);
    const [showDuplicateWeekModal, setShowDuplicateWeekModal] = useState<boolean>(false);

    useEffect(() => {
        const ready = !!branchId && !!week && !!planType;
        if (!ready) { return; }

        setLoading(true);
        getBranchWeeklyTimePlan(branchId!, week!, planType!)
            .then((res) => {
                setSelectedEmployees(res.data.meta.employees);
                const weeklyTimePlan = convertDailyTimePlanToWeeklyTimePlan(res.data);
                setDataSource(weeklyTimePlan);

                return res;
            })
            .finally(() => setLoading(false));

    }, [branchId, week, planType]);

    const hideStarterHint = useCallback(() => setShowStarterHint(false), []);

    const branchOptions = useMemo(
        () => branchList?.data.map(({ id: value, name: label }) => ({
            value,
            label,
        })),
        [branchList]
    );

    const columns: ProColumns<EmployeeWeeklyTimePlan>[] = [
        {
            title: t('role'),
            width: 150,
            dataIndex: 'role_id',
            valueType: 'select',
            request: async () => getListRoles().then((res) => {
                return res.data.map((role) => ({
                    label: role.name,
                    value: role.id,
                }));
            }),
            fieldProps: {
                disabled: !permissions.includes('employee-planning:manage'),
            },
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'Required',
                    },
                ],
            },
        },
        {
            title: t('time'),
            width: 180,
            key: 'scheduled_times',
            dataIndex: 'scheduled_times',
            valueType: 'timeRange',
            fieldProps: {
                disabled: !permissions.includes('employee-planning:manage'),
                changeOnBlur: true,
                format: 'HH:mm',
                minuteStep: 15,
                order: false,
            },
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'Required',
                    },
                ],
            },
        },
        ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, i) => ({
            title: ct(`day.short.${day}`) + ` (${week?.startOf('week').day(i+1).format('DD.MM')})`,
            width: 120,
            key: day,
            dataIndex: day,
            valueType: 'select' as ProFieldValueType,
            renderFormItem: (_: any, config: any) => {
                return (
                    <PersonnelSelect
                        roleId={config?.record?.role_id}
                        selectedEmployees={selectedEmployees}
                        disabled={!permissions.includes('employee-planning:assign')}
                        onSelect={(employee) => {
                            setSelectedEmployees((prevState): ListedEmployeeData[] => {
                                const newState = [
                                    ...prevState
                                ].filter((item) => item.id !== employee.id);

                                return [{...employee}, ...newState].slice(0, 5);
                            })
                        }}
                    />
                );
            },
            render: (_: any, row: any) => {
                return selectedEmployees.find(
                    (e) => e.id === row[`${day}`]
                )?.name;
            },
        })),
        {
            title: <SettingOutlined/>,
            align: 'center',
            valueType: 'option',
            width: 120,
            render: (_text, record, _number, action) => (
                <>
                    <Button
                        type="link"
                        key="edit"
                        onClick={() => action?.startEditable?.(record.id)}
                    >
                        {t('edit')}
                    </Button>
                    <EditableProTable.RecordCreator
                        key="duplicate"
                        newRecordType="dataSource"
                        record={record}
                    >
                        <Button type="link" disabled={!canUserManageAndAssign}>
                            {t('duplicate')}
                        </Button>
                    </EditableProTable.RecordCreator>
                </>
            ),
        },
    ];

    const toolbarRender = useCallback(() => ([
        <Toolbar
            branchId={branchId!}
            week={week!}
            planType={planType!}
            disableDownloadPdfButton={!branchId || !week || !planType}
            disableDownloadDetailedPdfButton={!branchId || !week || !planType}
        />
    ]), [branchId, week, planType]);

    const onSave = (
        _rowKey: any,
        data: EmployeeWeeklyTimePlan,
        _row: any,
    ) => {
        return upsertBranchRoleWeeklyTimePlan(
            branchId!,
            week!,
            {
                type: planType!,
                role_id: data.role_id!,
                scheduled_times: data.scheduled_times as [string, string],
                monday: data.monday,
                tuesday: data.tuesday,
                wednesday: data.wednesday,
                thursday: data.thursday,
                friday: data.friday,
                saturday: data.saturday,
                sunday: data.sunday,
                monday_col_id: data.monday_col_id,
                tuesday_col_id: data.tuesday_col_id,
                wednesday_col_id: data.wednesday_col_id,
                thursday_col_id: data.thursday_col_id,
                friday_col_id: data.friday_col_id,
                saturday_col_id: data.saturday_col_id,
                sunday_col_id: data.sunday_col_id,
                locale: dayjs.locale(),
                start_of_week: week!.startOf('week').format('YYYY-MM-DD'),
                end_of_week: week!.endOf('week').format('YYYY-MM-DD'),
                row_id: data.id,
            },
        )
            .then((res) => {
                setSelectedEmployees(res.data.meta.employees);

                const weeklyTimePlan = convertDailyTimePlanToWeeklyTimePlan(res.data);
                setDataSource(weeklyTimePlan);
                skipOnChange.current = true;

                return weeklyTimePlan;
            })
    };

    const onDelete = async (
        _rowKey: any,
        data: EmployeeWeeklyTimePlan,
    ) => {
        deleteBranchRoleWeeklyTimePlan(
            branchId!,
            week!,
            {
                type: planType!,
                monday_col_id: data.monday_col_id,
                tuesday_col_id: data.tuesday_col_id,
                wednesday_col_id: data.wednesday_col_id,
                thursday_col_id: data.thursday_col_id,
                friday_col_id: data.friday_col_id,
                saturday_col_id: data.saturday_col_id,
                sunday_col_id: data.sunday_col_id,
                locale: dayjs.locale(),
                start_of_week: week!.startOf('week').format('YYYY-MM-DD'),
                end_of_week: week!.endOf('week').format('YYYY-MM-DD'),
            },
        ).then((res) => {
            setSelectedEmployees(res.data.meta.employees);

            const weeklyTimePlan = convertDailyTimePlanToWeeklyTimePlan(res.data);
            setDataSource(weeklyTimePlan);
            skipOnChange.current = true;

            return weeklyTimePlan;
        });
    };

    const onWeekChange = useCallback((date: any) => {
        setWeek(date ? dayjs(date).startOf('week') : null);
    }, []);

    const headerTitle = (
        <>
            <Tooltip
                title={t('starter-hint')}
                open={!branchId && showStarterHint}
            >
                <Select
                    loading={loadingBranches}
                    placeholder="Branch"
                    style={styles.branchSelect}
                    value={branchId}
                    onClick={hideStarterHint}
                    onChange={setBranchId}
                    options={branchOptions}
                />
            </Tooltip>
            <DatePicker
                picker="week"
                value={week}
                onClick={hideStarterHint}
                onChange={onWeekChange}
            />
            <Select
                placeholder="Plan Type"
                style={{ width: 60 }}
                value={planType}
                onClick={hideStarterHint}
                onChange={setPlanType}
                options={planTypeOptions}
            />
            <Divider type="vertical" />
            <Button
                disabled={!branchId || !week || !planType || !canUserManageAndAssign}
                onClick={() => setShowDuplicateWeekModal(true)}
            >
                <CopyOutlined />
            </Button>
        </>
    )

    const canCreateNewRecords = !(
        branchId
        && week
        && planType
        && !loading
        && permissions.includes('employee-planning:manage')
    );

    return (
        <PageContainer>
            <Card>
                <Row>
                    <Col className="gutter-row" sm={24} lg={24}>
                        <EditableProTable<EmployeeWeeklyTimePlan>
                            rowKey="id"
                            value={dataSource}
                            onChange={(data) => {
                                if (!skipOnChange.current) {
                                    setDataSource(data);
                                } else {
                                    skipOnChange.current = false;
                                }
                            }}
                            scroll={{ x: 960 }}
                            maxLength={100}
                            headerTitle={headerTitle}
                            columns={columns}
                            toolBarRender={toolbarRender}
                            recordCreatorProps={getRecordCreatorProps(canCreateNewRecords)}
                            loading={loading}
                            editable={{
                                type: 'single',
                                onSave,
                                onDelete,
                                saveText: ct('ok'),
                                deleteText: ct('delete'),
                                onlyAddOneLineAlertMessage: t('one-line-alert-message'),
                                deletePopconfirmMessage: t('delete-popconfirm-message'),
                                actionRender: (_row, _config, dom) => [
                                    canUserManageOrAssign ? dom.save : false,
                                    canUserManageOrAssign ? dom.delete : false,
                                ].filter(Boolean),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <Card title={t('preview.title')}>
            </Card>
            <DuplicateWeekModal
                open={showDuplicateWeekModal}
                onClose={() => setShowDuplicateWeekModal(false)}
                initialBranchId={branchId}
                initialWeek={week}
            />
        </PageContainer>
    );
};

export default PersonnelPlanningIndex;
