import React from 'react';
import {
    LockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormCheckbox,
    ProFormText,
} from '@ant-design/pro-components';
import { Tabs, Button, message } from 'antd';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../../../utils/network/api/v1'
import logo from '../../../assets/logo-full-black.svg';
import axios from '../../../utils/network/axios';
// import dayjs from 'dayjs';
import './styles.css'
import styles from './styles';
import i18next from 'i18next';

const Login: React.FC = () => {
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<any>([]);
    const { t } = useTranslation('identity');
    const langSwitcher = (lang: string) => () => i18next.changeLanguage(lang);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        postLogin({
            email: values.username,
            password: values.password,
            device_name: 'web',
            remember: values.remember,
        }).then((response) => {
            const {
                token,
                token_type,
                user,
            } = response.data;

            // https://authkit.arkadip.dev/signin/
            if (signIn({
                auth: {
                    token,
                    type: token_type,
                },
                userState: user,
            })) {
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                navigate('/activities');
            } else {
                message.error(t('login.failed'));
            }
        }).catch((e) => {
            if (e.response && e.response.status === 422) {
                setErrors(e.response.data.errors);
            } else {
                message.error(e.message)
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div>
            <LoginForm
                logo={
                    <img
                        alt={'PatronX OPDS'}
                        width={328}
                        src={logo}
                    />
                }
                submitter={{
                    submitButtonProps: { loading },
                }}
                onFinish={handleSubmit}
            >
                <Tabs
                    activeKey="account"
                    items={[{
                        key: 'account',
                        label: t('account'),
                        children: (
                            <>
                                <ProFormText
                                    name="username"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <UserOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={t('email.placeholder')}
                                    validateStatus={errors.email ? 'error' : 'success'}
                                    help={errors.email}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('email.required'),
                                        },
                                    ]}
                                />
                                <ProFormText.Password
                                    name="password"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <LockOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={t('password.placeholder')}
                                    validateStatus={errors.password ? 'error' : 'success'}
                                    help={errors.password}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('password.required'),
                                        },
                                    ]}
                                />
                                <div
                                    style={{
                                        marginBlockEnd: 24,
                                    }}
                                >
                                    <ProFormCheckbox noStyle name="remember">
                                        {t('remember')}
                                    </ProFormCheckbox>
                                    <Button
                                        onClick={() => {
                                            navigate('/identity/forgot-password');
                                        }}
                                        type="link"
                                        style={{
                                            float: 'right',
                                        }}
                                    >
                                        {t('forgot')}
                                    </Button>
                                </div>
                            </>
                        ),
                    }]}
                />
            </LoginForm>
            <div style={styles.languageSelectorContainer}>
                <span onClick={langSwitcher('de')} className="fi fi-de" style={styles.flag}/>
                <span onClick={langSwitcher('tr')} className="fi fi-tr" style={styles.flag}/>
                <span onClick={langSwitcher('en')}  className="fi fi-gb"/>
            </div>
        </div>
    );
};

export default Login;
