import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { EmployeeContractHistoryResource } from '../../../../../../../types';
import { getEmployeeContractHistory } from '../../../../../../../utils/network/api/v1';
import { useTranslation } from 'react-i18next';

const highlight = (key: string) =>
    (value: string | number, o: EmployeeContractHistoryResource) =>
        value
            ? (
                Object.keys(o.updated_fields).includes(key)
                    ? <span style={{ color: 'red' }}><b>{value}</b></span>
                    : value
            )
            : '-';

const highlightAndTranslate = (t: Function, prefix: string) =>
    (value: string | number, o: EmployeeContractHistoryResource) =>
        value ? t(`${prefix}.${value}`) : '-';

const highlightAndFormatDate = (key: string) =>
    (value: string, o: EmployeeContractHistoryResource) =>
        value ? highlight(key)(dayjs(value).format('DD.MM.YYYY'), o) : '-';

const highlightAndFormatMoney = (key: string) =>
    (value: string | number, o: EmployeeContractHistoryResource) =>
        highlight(key)(value, o);

const columns = (t: Function): ColumnsType<EmployeeContractHistoryResource> => ([
    {
        title: t('form.contract-information.snapshot-date'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: highlightAndFormatDate('created_at'),
        fixed: 'left',
    },
    {
        title: t('form.contract-information.contract-form.title'),
        dataIndex: 'form',
        key: 'form',
        render: highlightAndTranslate(t, 'form.contract-information.contract-form.options'),
        fixed: 'left',
    },
    {
        title: t('form.contract-information.work-hours-per-month'),
        dataIndex: 'work_hours_per_month',
        key: 'work_hours_per_month',
        render: highlight('work_hours_per_month'),
        fixed: 'left',
    },
    {
        title: t('form.contract-information.contract-start-date'),
        dataIndex: 'start_date',
        key: 'start_date',
        render: highlightAndFormatDate('start_date'),
    },
    {
        title: t('form.contract-information.contract-end-date'),
        dataIndex: 'end_date',
        key: 'end_date',
        render: highlightAndFormatDate('end_date'),
    },
    {
        title: t('form.contract-information.salary-gross'),
        dataIndex: 'salary_gross',
        key: 'salary_gross',
        render: highlightAndFormatMoney('salary_gross'),
    },
    {
        title: t('form.contract-information.salary-net'),
        dataIndex: 'salary_net',
        key: 'salary_net',
        render: highlightAndFormatMoney('salary_net'),
    },
    {
        title: t('form.contract-information.salary-fixed'),
        dataIndex: 'salary_fixed',
        key: 'salary_fixed',
        render: highlightAndFormatMoney('salary_fixed'),
    },
    {
        title: t('form.contract-information.social-expense-employer'),
        dataIndex: 'social_expense_employer',
        key: 'social_expense_employer',
        render: highlightAndFormatMoney('social_expense_employer'),
    },
    {
        title: t('form.contract-information.social-expense-employee'),
        dataIndex: 'social_expense_employee',
        key: 'social_expense_employee',
        render: highlightAndFormatMoney('social_expense_employee'),
    },
    {
        title: t('form.contract-information.hourly-rate-gross'),
        dataIndex: 'hourly_rate_gross',
        key: 'hourly_rate_gross',
        render: highlightAndFormatMoney('hourly_rate_gross'),
    },
    {
        title: t('form.contract-information.hourly-rate-net'),
        dataIndex: 'hourly_rate_net',
        key: 'hourly_rate_net',
        render: highlightAndFormatMoney('hourly_rate_net'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-a'),
        dataIndex: 'work_hours_per_day_a',
        key: 'work_hours_per_day_a',
        render: highlight('work_hours_per_day_a'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-min-a'),
        dataIndex: 'work_hours_per_day_min_a',
        key: 'work_hours_per_day_min_a',
        render: highlight('work_hours_per_day_min_a'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-max-a'),
        dataIndex: 'work_hours_per_day_max_a',
        key: 'work_hours_per_day_max_a',
        render: highlight('work_hours_per_day_max_a'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-b'),
        dataIndex: 'work_hours_per_day_b',
        key: 'work_hours_per_day_b',
        render: highlight('work_hours_per_day_b'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-min-b'),
        dataIndex: 'work_hours_per_day_min_b',
        key: 'work_hours_per_day_min_b',
        render: highlight('work_hours_per_day_min_b'),
    },
    {
        title: t('form.contract-information.work-hours-per-day-max-b'),
        dataIndex: 'work_hours_per_day_max_b',
        key: 'work_hours_per_day_max_b',
        render: highlight('work_hours_per_day_max_b'),
    },
    {
        title: t('form.contract-information.work-days-per-week-a'),
        dataIndex: 'work_days_per_week_a',
        key: 'work_days_per_week_a',
        render: highlight('work_days_per_week_a'),
    },
    {
        title: t('form.contract-information.work-days-per-week-b'),
        dataIndex: 'work_days_per_week_b',
        key: 'work_days_per_week_b',
        render: highlight('work_days_per_week_b'),
    },
    {
        title: t('form.contract-information.bonus-premium.resource.title'),
        dataIndex: 'premium_resource',
        key: 'premium_resource',
        render: highlightAndTranslate(t, 'form.contract-information.bonus-premium.resource.options'),
    },
    {
        title: t('form.contract-information.bonus-premium.gross-net.title'),
        dataIndex: 'premium_gross_net',
        key: 'premium_gross_net',
        render: highlightAndTranslate(t, 'form.contract-information.bonus-premium.gross-net.options'),
    },
    {
        title: t('form.contract-information.bonus-premium.premium-rate'),
        dataIndex: 'premium_rate',
        key: 'premium_rate',
        render: highlight('premium_rate'),
    },
    // {
    //     title: 'Raw Updates',
    //     key: 'raw',
    //     render: (o) => <pre>{JSON.stringify(o.updated_fields, null, 2)}</pre>,
    // }
]);

type PersonnelContractHistoryProps = {
    contractId: number;
    employeeId: number;
}

const PersonnelContractHistory: React.FC<PersonnelContractHistoryProps> = ({
    contractId,
    employeeId,
}) => {
    const { data, loading } = useRequest(
        () => getEmployeeContractHistory(employeeId, contractId),
    );
    const { t } = useTranslation('personnel-files');

    return (
        <Table
            rowKey="id"
            columns={columns(t)}
            loading={loading}
            dataSource={data?.data.data}
            scroll={{ x: 3000 }}
            rowClassName={
                (o: EmployeeContractHistoryResource, index) =>
                    (index === 0 ? 'highlighted-contact-history-row' : '')
            }
        />
    );
};

export default PersonnelContractHistory;
