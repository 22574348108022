import { DashboardTranslations } from '../../types'

const dashboard: DashboardTranslations = {
    'upcoming-birthdays': {
        title: 'Upcoming Birthdays',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Upcoming Passport Expiration Dates',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Upcoming Visa Expiration Dates',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Upcoming Contract Expiration Dates',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Upcoming Residence Permit Expiration Dates',
    },
};

export default dashboard;
