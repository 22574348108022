import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ShopOutlined, TeamOutlined } from '@ant-design/icons';
import { dashable, getDashableDate } from '../../utils';
import { EmployeeViewResource } from '../../../../../../types';

const TitleDescription = ({ personnel, display }: { personnel: EmployeeViewResource, display: boolean }) => {
    const { t: ct } = useTranslation('common');
    const dashableDate = getDashableDate(ct);

    return (
        <div style={{ display: display ? 'block' : 'none' }}>
            <div>
                <span
                    style={{
                        display: 'inline-block',
                        paddingRight: 10,
                    }}
                >
                    {dashable(personnel?.contracts[0]?.role?.name)}
                </span>
                <span
                    style={{
                        display: 'inline-block',
                        paddingRight: 10,
                    }}
                >
                    <ShopOutlined/>&nbsp;
                    {dashable(personnel?.contracts[0]?.branch?.name)}
                </span>
                <span
                    style={{
                        display: 'inline-block',
                        paddingRight: 10,
                    }}
                >
                    <TeamOutlined/>&nbsp;
                    {dashable(personnel?.contracts[0]?.work_area?.name)}
                </span>
            </div>
            {personnel.start_date && (
                <div>
                    {ct('since', {
                        date: dashableDate(personnel.start_date),
                    })}&nbsp;
                    ({dayjs(personnel.start_date).fromNow(true)})
                </div>
            )}
        </div>
    );
};

export default TitleDescription;
