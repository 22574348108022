import React from 'react';
import { Button, Result, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { KeyOutlined } from '@ant-design/icons';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../utils/hooks';

const {
    Paragraph,
    Text,
} = Typography;

type RbacProps = {
    permission: string,
    children: React.ReactNode,
};

const Rbac: React.FC<RbacProps> = ({ permission, children }) => {
    const navigate = useNavigate();
    const permissions = usePermissions();

    const { t } = useTranslation('identity');

    if (!permissions?.includes(permission)) {
        return (
            <Result
                status="403"
                title={t('rbac.errors.access-denied.title')}
                subTitle={t('rbac.errors.access-denied.subtitle')}
                extra={
                    <Button type="primary" onClick={() => navigate('/dashboard')}>
                        {t('rbac.errors.access-denied.actions.go-home')}
                    </Button>
                }
            >
                <div className="desc">
                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            { t('rbac.errors.access-denied.hint') }
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <KeyOutlined className="site-result-demo-error-icon" style={{ fontSize: 16 }} />
                        &nbsp;
                        <Paragraph
                            style={{
                                display: 'inline',
                            }}
                            copyable
                        >{permission}</Paragraph>
                    </Paragraph>
                </div>
            </Result>
        );
    }

    return (<>{children}</>);
};

export default Rbac;
