import { PersonnelPlanningTranslations } from '../../types';

const personnelPlanning: PersonnelPlanningTranslations = {
    'role': 'Rolle',
    'time': 'Uhrzeit',
    'starter-hint': 'Bitte wählen Sie eine Filiale aus, um mit der Planung der Woche zu beginnen.',
    'edit': 'Bearbeiten',
    'duplicate': 'Duplizieren',
    'one-line-alert-message': 'Sie können nur eine Zeile auf einmal hinzufügen.',
    'delete-popconfirm-message': 'Sind Sie sicher, dass Sie diese Zeile löschen möchten?',
    'personnel-select': {
        'preview-hint': 'Bitte klicken Sie auf das ⓘ Symbol, um den Mitarbeiter zu sehen.',
        'option-groups': {
            'search-results': {
                'title': 'Suchergebnisse',
            },
            'previously-selected-results': {
                'title': 'Zuvor ausgewählte Ergebnisse',
            },
        },
        'start-typing-to-search': 'Geben Sie zum Suchen einen Text ein...',
    },
    'preview': {
        'title': 'Personalvorschau',
    },
    'duplicate-week-modal': {
        'title': 'Duplizieren Die Wochenplanung',
        'form': {
            'from': 'Von',
            'to': 'Zu',
            'branch': 'Filiale',
            'week': 'Woche',
            result: {
                success: 'Die Wochenplanung wurde erfolgreich dupliziert.',
                error: 'Die Wochenplanung konnte nicht dupliziert werden.',
            },
        },
    },
};

export default personnelPlanning;
