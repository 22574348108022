import React from 'react';

const Text: React.FC<{
    value?: string
    empty?: React.ReactElement<any, any> | string | null
    mode?: 'normal' | 'h1' | 'h2' | 'h3' | 'p'
}> = ({
    value,
    mode,
    empty,
    ...props
}) => {
    const tagName = mode === 'normal' || !mode ? 'div' : mode;

    return React.createElement(tagName, props, value || empty || '-');
};

export default Text;
