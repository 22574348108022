import { PersonnelFilesTranslations } from '../../types';

const personnelFiles: PersonnelFilesTranslations = {
    'index': {
        'full-name': 'Vollständiger Name',
        'company': 'Unternehmen',
        'role': 'Rolle',
        'branch': 'Filiale',
        'actions': 'Aktionen',
        'create-personnel-file': 'Personalakte erstellen',
        'filters': {
            'state': {
                'options': {
                    'all': 'Alle',
                    'active': 'Aktiv',
                    'inactive': 'Inaktiv',
                },
            },
        },
    },
    'view': {
        'title': '{{full_name}}',
        'sections': {
            'personal-information': 'Personal',
            'address-information': 'Adress',
            'passport-information': 'Ausweis & Reisepass',
            'tax-and-social-security-information': 'Steuer und Sozialversicherung',
            'payment-information': 'Zahlung',
            'work-information': 'Arbeit',
            'contract-information': 'Vertrag',
            'personal-health-information': 'Persönliche & Gesundheit',
            'health-passport-information': 'Gesundheitspass',
            'emergency-contact-information': 'Notfallkontakt',
        },
        'account-management': {
            'title': 'Konto verwalten',
        },
    },
    'create-personnel-file': {
        'title': 'Personalakte erstellen',
    },
    'edit-personnel-file': {
        'title': 'Personalakte bearbeiten',
    },
    'form': {
        'submit': {
            'result': {
                'success': 'Die Personalakte wurde erfolgreich gespeichert.',
                'error': 'Die Personalakte konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
            },
        },
        'personnel-information': {
            'title': 'Personalinformationen',
            'first-name': 'Vorname',
            'last-name': 'Nachname',
            'full-name': 'Vollständiger Name',
            'birth-name': 'Geburtsname',
            'email-address': 'E-Mail',
            'phone-number': 'Telefonnummer',
        },
        'address-information': {
            'title': 'Adressinformationen',
            'address-street': 'Straße',
            'address-number': 'Hausnummer',
            'address-postcode': 'Postleitzahl',
            'address-city': 'Stadt',
        },
        'passport-information': {
            'title': 'Ausweis & Reisepassinformationen',
            'birth-city': 'Geburtsort',
            'birth-date': 'Geburtsdatum',
            'birth-country': 'Geburtsland',
            'identity-document': {
                'title': 'Identitätstype',
                'types': {
                    'national-id': 'Personalausweis',
                    'passport': 'Reisepass',
                },
            },
            'identity-document-number': 'Ausweis/Pass Nummer',
            'identity-document-expiration-date': 'Ausweis/Pass Gültig bis',
            'nationality': 'Nationalität',
            'ethnicity': 'Staatsangehörigkeit',
            'residence-permit': {
                'title': 'Aufenthaltserlaubnis',
                'types': {
                    'visa': 'Visum',
                    'tourist-visa': 'Touristenvisum',
                    'work-visa': 'Arbeitsvisum',
                    'student-visa': 'Studentenvisum',
                    'family-reunion-visa': 'Familienzusammenführungsvisum',
                    'asylum': 'Asyl',
                    'refugee': 'Flüchtling',
                    'eu': 'EU',
                    'eu-blue-card': 'EU Blue Card',
                    'permanent-residence': 'Niederlassungserlaubnis',
                    'citizenship': 'Staatsbürgerschaft',
                },
            },
            'residence-permit-expiration-date': 'Aufenthaltserlaubnis Gültig bis',
        },
        'tax-and-social-security-information': {
            'title': 'Steuer und Sozialversicherungsinformationen',
            'tax-id': 'Steuer-ID',
            'health-insurance-company-name': 'Krankenkasse',
            'health-insurance-number': 'Krankenkasse Nummer',
            'social-security-number': 'Sozialversicherungsnummer',
            'marital-status': {
                'title': 'Familienstand',
                'options': {
                    'single': 'Ledig',
                    'married': 'Verheiratet',
                    'divorced': 'Geschieden',
                    'widowed': 'Verwitwet',
                },
            },
            'has-children': {
                'title': 'Kinder?',
                'options': {
                    'yes': 'Ja',
                    'no': 'Nein',
                },
            },
            'employee-children': {
                'title': 'Kinder',
                'name': {
                    'placeholder': 'Name',
                },
                'birth_date': {
                    'placeholder': 'Geburtsdatum',
                },
            },
            'children-count': 'Anzahl der Kinder',
            'child-allowance': 'Kinderfreibetrag',
            'other-employment': {
                'title': 'Andere Beschäftigung?',
                'options': {
                    'yes': 'Ja',
                    'no': 'Nein',
                },
            },
            'other-employment-type': {
                'title': 'Beschäftigungstyp',
                'options': {
                    'mini-job': 'Mini-Job',
                    'subject-to-social-insurance': 'Sozialversicherungspflichtig',
                },
            },
            'other-employment-employer': 'Arbeitgeber',
        },
        'payment-information': {
            'title': 'Zahlungsinformationen',
            'bank-name': 'Bankname',
            'bank-iban': 'IBAN Nummer',
            'bank-bic': 'BIC Nummer',
            'bank-account-holder': 'Kontoinhaber',
            'different-bank-account-holder': 'Anderer Kontoinhaber',
        },
        'work-information': {
            'title': 'Arbeitsinformationen',
            'application-date': 'Bewerbungsdatum',
            'application-role-id': 'Bewerbungsrolle',
            'probation-start-date': 'Probezeitbeginn',
            'probation-branch-id': 'Probezeitfiliale',
            'probation-days': {
                'title': 'Probetage',
                'options': {
                    '1': '1 Tag',
                    '2': '2 Tage',
                    '3': '3 Tage',
                },
            },
            'start-date': 'Arbeitstartdatum',
            'end-date': 'Arbeitsenddatum',
            'personnel-number': 'Personalnummer',
            'has-driving-license': {
                'title': 'Führerschein?',
                'options': {
                    'yes': 'Ja',
                    'no': 'Nein',
                },
            },
            'driving-license-number': 'Führerscheinnummer',
            'tasks': 'Aufgaben',
            'skills': 'Fähigkeiten',
            'education-school': 'Schulische Bildung',
            'work-experience': 'Berufserfahrung',
        },
        'contract-information': {
            'title': 'Vertragsinformationen',
            'history': 'Vertragsverlauf',
            'snapshot-date': 'Snapshot-Datum',
            'contract-form': {
                'title': 'Vertragsform',
                'options': {
                    'full_time': 'Vollzeit',
                    'part_time': 'Teilzeit',
                    'mini_job': 'Mini-Job',
                    'working_student': 'Werkstudent',
                },
            },
            'contract-start-date': 'Vertragsbeginn',
            'contract-end-date': 'Vertragsende',
            'primary-branch-id': 'Branche',
            'primary-role-id': 'Rolle',
            'primary-work-area-id': 'Arbeitsbereich',
            'salary-gross': 'Bruttogehalt',
            'salary-net': 'Nettogehalt',
            'tax-class': {
                'title': 'Steuerklasse',
                'options': {
                    '1': 'Klasse 1',
                    '2': 'Klasse 2',
                    '3': 'Klasse 3',
                    '4': 'Klasse 4',
                    '5': 'Klasse 5',
                    'G': 'Klasse G',
                },
            },
            'social-expense-employer': 'Arbeitgeber Sozialversicherung',
            'social-expense-employee': 'Arbeitnehmer Sozialversicherung',
            'work-hours-per-month': 'Arbeitsstunden pro Monat',
            'work-hours-per-month-error': 'Arbeitsstunden pro Monat muss zwischen {{min}} und {{max}} liegen.',
            'hourly-rate-gross': 'Brutto Stundenlohn',
            'hourly-rate-net': 'Netto Stundenlohn',
            'work-hours-per-day-a': 'Arbeitsstunden pro Tag',
            'work-hours-per-day-min-a': 'Mindestarbeitsstunden pro Tag',
            'work-hours-per-day-max-a': 'Maximalarbeitsstunden pro Tag',
            'work-days-per-week-a': 'Arbeitstage pro Woche',
            'work-days-per-month-a': 'Arbeitstage pro Monat',
            'salary-fixed': 'Festgehalt',
            'work-hours-per-day-b': 'Arbeitsstunden pro Tag',
            'work-hours-per-day-min-b': 'Mindestarbeitsstunden pro Tag',
            'work-hours-per-day-max-b': 'Maximalarbeitsstunden pro Tag',
            'work-days-per-week-b': 'Arbeitstage pro Woche',
            'work-days-per-month-b': 'Arbeitstage pro Monat',
            'meal-cost-per-day-a': 'Tägliche essenskosten',
            'meal-cost-per-day-b': 'Tägliche essenskosten',
            'bonus-premium': {
                'title': 'Bonusprämie',
                'resource': {
                    'title': 'Ressource',
                    'options': {
                        'holding_total': 'Holding gesamt',
                        'company_total': 'Unternehmen gesamt',
                        'branch_total': 'Fillialen gesamt',
                        'personal_total': 'Personal gesamt',
                        'kitchen_total': 'Küche gesamt',
                        'bar_total': 'Bar gesamt',
                    },
                },
                'gross-net': {
                    'title': 'Brutto/Netto',
                    'options': {
                        'gross': 'Brutto',
                        'net': 'Netto',
                    },
                },
                'premium-rate': 'Prämienrate',
            },
        },
        'personal-health-information': {
            'title': 'Persönliche & Gesundheitsinformationen',
            'hobbies': 'Hobbys',
            'height': 'Größe',
            'weight': 'Gewicht',
            'blood-type': 'Blutgruppe',
            'diseases': 'Krankheiten',
            'medications': 'Medikamente',
            'addictions': 'Süchte',
        },
        'health-passport-information': {
            'title': 'Gesundheitspassinformationen',
            'has-health-passport': 'Gesundheitspass?',
            'health-passport-issue-date': 'Gesundheitspass ausgestellt am',
            'health-passport-expiration-date': 'Gesundheitspass läuft ab',
        },
        'emergency-contact-information': {
            'title': 'Notfallkontaktinformationen',
            'name': 'Name',
            'relationship': 'Beziehung',
            'phone-number': 'Telefonnummer',
            'email-address': 'E-Mail-Adresse',
            'address': 'Adresse',
        },
        'sections': {
            'personal-information': 'Personal',
            'address-information': 'Adress',
            'passport-information': 'Ausweis & Reisepass',
            'tax-and-social-security-information': 'Steuer und Sozialversicherung',
            'payment-information': 'Zahlung',
            'work-information': 'Arbeit',
            'contract-information': 'Vertrag',
            'personal-health-information': 'Persönliche & Gesundheit',
            'health-passport-information': 'Gesundheitspass',
            'emergency-contact-information': 'Notfallkontakt',
        },
    },
    'account-management': {
        'new-user': {
            'description': 'Dieser Mitarbeiter hat noch <b>kein</b> Benutzerkonto.',
            'create-user': {
                'description': 'Um ein Benutzerkonto für diesen Mitarbeiter zu erstellen, klicken Sie auf <b>Benutzer erstellen</b>.',
                'note-1': 'Der Benutzername wird die E-Mail-Adresse des Mitarbeiters sein.',
                'note-2': 'Ein Passwort wird an diese E-Mail-Adresse gesendet.',
                'note-3': 'Sie können die Zugriffsrechte nach dem Erstellen des Kontos bearbeiten.',
                'action': {
                    'text': 'Benutzer Erstellen',
                },
            },
        },
        'existing-user': {
            'description': 'Dieser Mitarbeiter hat ein Benutzerkonto, mit dem er sich anmelden kann.',
            'send-password': {
                'title': 'Personalschlüssel',
                'action': {
                    'text': 'Passwort Senden',
                },
                'success': {
                    'description': 'Das Passwort wurde auf <b>{{password}}</b> gesetzt und an die E-Mail-Adresse des Mitarbeiters gesendet.',
                    'note': '<b>Hinweis</b>: Dieses Passwort wird nur einmal angezeigt.',
                },
                'error': 'Das Passwort konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
            },
            'delete-user': {
                'action': {
                    'text': 'Benutzer Löschen',
                },
                'error': 'Der Benutzer konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
            },
            'permissions': {
                'title': 'Berechtigungen',
            },
        },
    },
};

export default personnelFiles;
