import { Avatar, Card, List } from 'antd';
import React from 'react';
import { useRequest } from 'ahooks';
import { getUpcomingIdentityDocumentExpirationDates } from '../../../../../utils/network/api/v1';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UpcomingPassportExpirationDates = () => {
    const navigate = useNavigate();
    const { data, loading } = useRequest(getUpcomingIdentityDocumentExpirationDates);
    const { t } = useTranslation('dashboard');

    return (
        <Card title={t('upcoming-identity-document-expiration-dates.title')} loading={loading}>
            <List
                dataSource={data?.data.data}
                loading={loading}
                renderItem={item => (
                    <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/files/personnel/${item.id}`)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.photo_url} />}
                            title={item.full_name}
                            description={`${item.diff_for_humans}`}
                        />
                        <div>
                            <span>
                                { dayjs(item.identity_document_expiration_date).format('DD.MM.YYYY') }
                            </span>
                            <br/><br/>
                        </div>
                    </List.Item>
                )}
                pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true,
                }}
            />
        </Card>
    );
};

export default UpcomingPassportExpirationDates;
