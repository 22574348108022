import React from 'react';
import {
    SendOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormText,
} from '@ant-design/pro-components';
import { Tabs, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postForgotPassword } from '../../../utils/network/api/v1';
import logo from '../../../assets/logo-full-black.svg';
import './styles.css';

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<any>([]);
    const { t } = useTranslation('identity');

    const handleSubmit = async (values: any) => {
        setLoading(true);
        postForgotPassword({
            email: values.username,
            device_name: 'web',
        }).then((response) => {
            if (response.data) {
                Modal.success({
                    title: t('forgot-password.success.title'),
                    content: t('forgot-password.success.content'),
                    okText: t('forgot-password.success.button.ok'),
                    onOk() {
                        navigate('/identity/login');
                    },
                });
            } else {
                message.error(t('forgot-password.failure.content'));
            }
        }).catch((e) => {
            if (e.response && e.response.status === 422) {
                setErrors(e.response.data.errors);
            } else {
                message.error(e.message);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div>
            <LoginForm
                logo={
                    <img
                        alt={'PatronX OPDS'}
                        width={328}
                        src={logo}
                    />
                }
                submitter={{
                    searchConfig: {
                        submitText: t('forgot-password.button.submit'),
                    },
                    submitButtonProps: {
                        loading,
                        icon: <SendOutlined />
                    },
                }}
                onFinish={handleSubmit}
            >
                <Tabs
                    activeKey="account"
                    items={[{
                        key: 'account',
                        label: t('forgot'),
                        children: (
                            <>
                                <ProFormText
                                    name="username"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <UserOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={t('email.placeholder')}
                                    validateStatus={errors.email ? 'error' : 'success'}
                                    help={errors.email}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('email.required'),
                                        },
                                    ]}
                                />
                            </>
                        ),
                    }]}
                />
            </LoginForm>
        </div>
    );
};

export default ForgotPassword;
